<template>
    <div>
        <b-modal
            id="modal-dossier-courant"
            cancel-title="Non"
            centered
            header-bg-variant="info"
            header-text-variant="white"
            no-close-on-backdrop
            ok-title="Changer de dossier"
            ok-variant="outline-info"
            title="Changement de dossier"
            v-model="modalShow"
            @cancel="newAppelNumero = null"
            @ok="$emit('selectappel', newAppelNumero); newAppelNumero = null"
        >
            Voulez-vous sélectionner le dossier <strong>#{{ newAppelNumero }}</strong> sur cette page ?
            <b-form-group class="mt-2 mb-0">
                <b-form-checkbox v-model="doNotAsk" @input="pushPreference()">Ne plus me demander</b-form-checkbox>
                <div class="text-muted small">
                    Vous pouvez modifier cette préférence dans la page <a :href="monProfilUrl" target="_blank">"Mon profil"</a>.
                </div>
            </b-form-group>
        </b-modal>
    </div>
</template>

<script>
import Request from '../../../utils/Request';
import Router from '../../../utils/Router';

export default {
    data: function () {
        return {
            doNotAsk: false,
            modalShow: false,
            newAppelNumero: null,
        };
    },
    props: {
        confirm: {
            type: Boolean,
            required: false,
            default: false,
        },
        confirmSynchroInitial: {
            type: Boolean,
            required: true,
        },
        currentAppelNumero: {
            type: String,
            required: false,
            default: null,
        },
        utilisateur: {
            type: String,
            required: true,
        },
    },
    beforeMount() {
        webSocket.subscribe('utilisateur/' + this.utilisateur, (url, data) => {
            if (
                'dossierCourant' === data.type
                && data.newAppelNumero
                && this.currentAppelNumero !== data.newAppelNumero
                && this.newAppelNumero !== data.newAppelNumero
            ) {
                if (data.force && !this.confirm) {
                    this.$emit('selectappel', data.newAppelNumero);
                } else {
                    this.newAppelNumero = data.newAppelNumero;
                    this.modalShow = true;
                }
            } else if ('preference' === data.type && 'confirmSynchro' === data.preference) {
                this.doNotAsk = !data.value;
            }
        });
        this.doNotAsk = !this.confirmSynchroInitial;
    },
    mounted() {
        if (this.currentAppelNumero) {
            // Ouverture d'un dossier directement dans l'URL
            this.notify(this.currentAppelNumero);
        }
    },
    computed: {
        monProfilUrl() {
            return Router.generate('mon-profil.edit');
        }
    },
    methods: {
        notify(newAppelNumero) {
            webSocket.publish('utilisateur/' + this.utilisateur, {
                type: 'dossierCourant',
                newAppelNumero: newAppelNumero,
            });
        },
        pushPreference() {
            Request.fetchJson(Router.generate('mon-profil.confirm-synchro', {value: this.doNotAsk ? 'false' : 'true'}), 'PATCH');
        },
    },
    watch: {
        currentAppelNumero(newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                // Changement de dossier dans la page
                this.notify(newValue);
            }
        },
    },
}
</script>
