<template>
  <menu-dropdown v-model="open">
    <template #button>
      <div @click="toggle" class="menu-dropdown-btn-item cti-poste">
        <i class="fa fa-phone-office" :class="'text-'+statusVariant"></i>
        <div class="text-normal">{{ poste }}</div>
      </div>
    </template>
    <template #dropdown>
      <div class="menu-dropdown-content-title">
        <h3>Ma téléphonie</h3>
        <div>
          <div class="btn-group">
            <button
                v-for="(status2, key) in statuses"
                class="btn btn-outline-light px-2 py-0"
                :class="{'active': status === key}"
                :disabled="statusLoading || status === 'BUSY'"
                :title="status2.label"
                @click="setStatus(key)"
            >
              <i :class="[status === key ? 'text-'+status2.variant : 'text-muted', status2.icon]"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="px-3 py-2">
        <div class="input-group">
          <input
              v-model="searchQuery"
              class="form-control input-sm"
              placeholder="Composer ou rechercher…"
              type="text"
              @keyup="onKeyup($event)"
              @input="onSearchInput()"
          >
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="call(searchQuery)">
              <i class="fa-solid fa-phone"></i>
            </button>
          </div>
        </div>
        <div v-if="searchQuery" class="position-relative">
          <div class="cti-search-results">
            <b-overlay :show="searchLoading" spinner-small rounded opacity="0.6">
              <div
                  v-for="(result, resultIndex) in searchResults"
                  class="cti-search-results-item"
                  :class="{'cti-search-results-item-focus': resultIndex === searchIndex}"
                  style="margin: 4px;border-radius: 4px;"
              >
                <div class="d-flex p-2 px-3 cursor-pointer" @click="selectAppel(result.numero)" title="Sélectionner l'appel">
                  <div class="text-normal text-dark">{{ result._metaNom || '#' + result.numero + ' ' + result._metaLieu }}</div>
                  <div class="ml-auto">
                    <i class="icon-people"></i>
                  </div>
                </div>
                <div class="d-flex px-3 cti-search-results-item-numbers">
                  <template v-for="telephone in ['telephone1', 'telephone2']">
                    <div
                        v-if="result[telephone]"
                        class="px-2 cti-search-results-item-number cursor-pointer"
                        :class="{'cti-search-results-item-number-focus': telephone === searchNumberIndex}"
                        @click="selectNumero(resultIndex, telephone)"
                    >
                      <i class="fa-solid" :class="'telephone1' === telephone ? 'fa-phone' : 'fa-map-location-dot'"></i> {{ result[telephone] }}
                    </div>
                  </template>
                </div>
              </div>
              <div v-if="!searchResults.length" class="text-muted px-3 py-2">
                Aucun résultat
              </div>
            </b-overlay>
          </div>
        </div>
      </div>
    </template>
  </menu-dropdown>
</template>

<script>
import Request from '../../../utils/Request';
import Router from '../../../utils/Router';
import MenuDropdown from "../MenuDropdown.vue";

export default {
  components: {MenuDropdown},
  data: function () {
    return {
      open: false,
      statusLoading: false,
      searchLoading: false,
      appels: [],
      searchQuery: '',
      searchResults: [],
      searchIndex: 0,
      searchNumberIndex: 'telephone1',
      abortController: new AbortController(),
    };
  },
  props: {
    poste: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    statusVariant() {
      switch (this.status) {
        case 'BUSY':
          return 'danger';
        case 'PAUSE':
          return 'info';
        case 'WRAPUP':
          return 'primary';
        case 'READY':
          return 'success'
      }

      return 'secondary';
    },
    statuses() {
      const statuses = {
        'UNAVAILABLE': {label: 'Déconnecté', variant: 'dark', icon: 'fa-solid fa-circle-xmark'},
        'PAUSE': {label: 'Absent', variant: 'info', icon: 'fa-solid fa-circle-pause'},
      }

      if (this.status === 'WRAPUP') {
        statuses.WRAPUP = {label: 'Disponible', variant: 'primary', icon: 'fa-solid fa-circle-check'}
      } else {
        statuses.READY = {label: 'Disponible', variant: 'success', icon: 'fa-solid fa-circle-check'}
      }

      return statuses;
    },
    searchItem() {
      if (this.searchResults.length) {
        return this.searchResults[this.searchIndex];
      }

      return null;
    },
    searchSelectedNumber() {
      if (this.searchItem) {
        return this.searchItem[this.searchNumberIndex];
      }

      return null;
    },
  },
  methods: {
    setStatus(newStatus) {
      if (newStatus !== this.status) {
        this.statusLoading = true;
        switch (newStatus) {
          case 'READY':
            webSocket.publish('cti', {action: 'agentReady'});
            break;
          case 'PAUSE':
            webSocket.publish('cti', {action: 'agentPause'});
            break;
          case 'UNAVAILABLE':
            break;
        }
      }
    },
    call(number) {
      this.searchQuery = '';
      if (number !== '') {
        webSocket.publish('cti', {action: 'call', number});
      }
    },
    toggle() {
      this.open = !this.open;
    },
    onSearchInput: Request.debounce(function () {
      this.searchIndex = 0;
      this.searchNumberIndex = 'telephone1';

      if (this.searchQuery.length > 1) {
        this.searchLoading = true;
        this.abortController.abort();
        this.abortController = new AbortController();
        Request.fetchJson(
            Router.generate('appel.search', {page: 1, 'appel_filter[s]': this.searchQuery, direction: 'desc', ordre: 'a.horodatage'}),
            'GET',
            null,
            this.abortController
        ).then((results) => {
          this.searchResults.splice(0, this.searchResults.length, ...results.items);
          this.searchLoading = false;
        });
      } else {
        this.searchResults.splice(0, this.searchResults.length);
        this.searchLoading = false;
      }
    }, 300),
    onKeyup(e) {
      if (e.key === 'Escape') {
        if (this.searchQuery !== '') {
          this.searchQuery = '';
          return;
        }
      }

      if (e.key === 'Enter') {
        this.call(this.searchSelectedNumber || this.searchQuery);
        this.searchQuery = '';
        return;
      }

      if (!this.searchResults.length) {
        return;
      }

      if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
        let newIndex = this.searchIndex + ('ArrowUp' === e.key ? -1 : 1);
        if (newIndex in this.searchResults) {
          this.searchIndex = newIndex;
        } else {
          this.searchIndex = ('ArrowUp' === e.key ? this.searchResults.length - 1 : 0);
        }
        this.searchNumberIndex = 'telephone1';
        e.preventDefault();
      } else if ('ArrowLeft' === e.key && this.searchItem.telephone1) {
        this.searchNumberIndex = 'telephone1';
        e.preventDefault();
      } else if ('ArrowRight' === e.key && this.searchItem.telephone2) {
        this.searchNumberIndex = 'telephone2';
        e.preventDefault();
      }
    },
    selectNumero(searchIndex, searchNumberIndex) {
      this.searchIndex = searchIndex;
      this.searchNumberIndex = searchNumberIndex;
    },
    selectAppel(numero) {
      document.dispatchEvent(new CustomEvent('app.selectAppel', {detail: {numero: numero}}));
      this.open = false;
    },
  },
  watch: {
    status() {
      this.statusLoading = false;
    },
  },
}
</script>