import '../scss/app.scss';
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import VueContextMenu from 'vue-context-menu'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import VueCompositionAPI from '@vue/composition-api';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import WebSocket from "./utils/WebSocket";
import './tiles.js';
import VueTheMask from 'vue-the-mask';

const VueUploadComponent = require('vue-upload-component');

Vue.config.productionTip = false;

require('./utils/Prototypes');
require('./app/0-App.js');
require('./app/Layout.js');
require('./app/Cti.js');

require('bootstrap');

require('script-loader!../../vendor/gos/web-socket-bundle/public/js/vendor/autobahn.min.js');
require('script-loader!../../vendor/gos/web-socket-bundle/public/js/websocket');

if (document.body.dataset.auth) {
    const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
    global.webSocket = new WebSocket(protocol + '://' + window.location.host + '/ws');

    document.addEventListener('websocket', (e) => {
        const websocketError = document.getElementById('webSocketError');
        if (websocketError) {
            websocketError.classList.toggle('d-none', e.detail.online);
        }
    });
}

Vue.use(BootstrapVue);
Vue.use(VueCompositionAPI);
Vue.use(VueContextMenu);
Vue.use(VueTheMask);

Vue.component('v-select', vSelect);
Vue.component('treeselect', Treeselect);
Vue.component('file-upload', VueUploadComponent)
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

const globals = {
    install(Vue, options) {
        Vue.prototype.sanitize = (html) => {
            let doc = new DOMParser().parseFromString(html, 'text/html');
            return doc.body.textContent || "";
        }
    }
}
Vue.use(globals);

const requireComponent = require.context('./vue/Components', true, /\.vue$/);
requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName);
    let parts = fileName.split('/');
    parts.shift();

    Vue.component(parts.join('').replace('.vue', ''),componentConfig.default || componentConfig);
});

const requireFilter = require.context('./vue/Filters', true, /\.js$/);
requireFilter.keys().forEach(fileName => {
    const filter = requireFilter(fileName);
    let parts = fileName.split('/');
    parts.shift();

    Vue.filter(parts.join('').replace('.js', '').toLowerCase(), filter.default);
});

if (document.body.dataset.module) {
    const module = require('./app/'+document.body.dataset.module+'.js');

    new module.default();
}
