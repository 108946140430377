var render = function render(){var _vm=this,_c=_vm._self._c;return _c('trumbowyg',{attrs:{"config":{
        autogrow: true,
        btns: [
            ['formatting'],
            ['strong', 'em', 'underline', 'del'],
            ['foreColor', 'backColor'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['horizontalRule'],
            ['removeformat'],
        ],
        lang: 'fr',
    },"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event)}}})
}
var staticRenderFns = []

export { render, staticRenderFns }