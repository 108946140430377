<template>
    <b-overlay :show="loading" spinner-small rounded :opacity="0.6" class="d-inline-block">
        <b-button v-bind="$attrs" :variant="variant" @click="confirm ? $bvModal.show(id) : call(); $emit('click', $event)">
            <i v-if="icon" :class="icon"></i>
            <span class="d-none d-md-inline">
                <slot></slot>
            </span>
        </b-button>
        <b-modal
            v-if="confirm"
            :id="id"
            cancel-title="Non"
            centered
            header-bg-variant="danger"
            header-text-variant="white"
            no-close-on-backdrop
            ok-title="Oui"
            ok-variant="outline-danger"
            :title="confirmTitle"
            @ok="call()"
        >
            {{ confirmMessage }}
        </b-modal>
    </b-overlay>
</template>

<script>
import Request from "../../../utils/Request";

export default {
    data: function () {
        return {
            id: Math.random().toString(16).slice(2),
            loading: false,
        };
    },
    props: {
        confirm: {
            type: Boolean,
            required: false,
            default: false,
        },
        confirmMessage: {
            type: String,
            required: false,
            default: 'Êtes-vous sûr(e) ?'
        },
        confirmTitle: {
            type: String,
            required: false,
            default: 'Confirmation'
        },
        icon: {
            type: String,
            required: false,
        },
        data: {
            type: Object,
            required: false,
            default: function () {
                return null;
            },
        },
        method: {
            type: String,
            required: false,
            default: 'GET',
        },
        url: {
            type: String,
            required: true,
        },
        variant: {
            type: String,
            required: false,
            default: 'light',
        }
    },
    methods: {
        call() {
            this.loading = true;
            Request.fetchJson(this.url, this.method, this.data)
                .then(data => {
                    if (data.success) {
                        data.flash && document.dispatchEvent(new CustomEvent('app.flash', {
                            detail: {
                                type: 'success',
                                flash: data.flash
                            }
                        }));
                        this.$emit('success', data);
                    } else if (data.errors) {
                        this.$emit('errors', data.errors);
                    } else {
                        this.handleError(data.flash);
                    }
                })
                .finally(() => {
                    this.loading = false;
                    this.$emit('loaded');
                })
            ;
        },
        handleError(flash = 'Une erreur est survenue') {
            document.dispatchEvent(new CustomEvent('app.flash', {detail: {type: 'error', flash}}));
        },
    },
}
</script>