<template>
    <div>
        <input
            v-bind="$attrs"
            class="form-control"
            :class="inputClass"
            :readonly="readonly"
            type="text"
            :value="value"
            @input="$emit('input', $event.target.value)"
            @blur="onBlur()"
        />
    </div>
</template>

<script>
import 'corejs-typeahead'
import Bloodhound from "bloodhound-js";
import Router from '../../../utils/Router';
import jQuery from 'jquery';
import Request from "../../../utils/Request";

export default {
    inheritAttrs: false,
    props: ['value', 'route', 'routeParams', 'readonly', 'minLength'],
    watch: {
        value() {
            this.jQueryElement.typeahead('val', this.value);
        }
    },
    data() {
        return {
            inputClass: null,
        };
    },
    computed: {
        jQueryElement() {
            return jQuery(this.$el.children[0]);
        }
    },
    mounted() {
        // fix car class/style exclu de inheritAttrs, voir avec vue v3...
        this.inputClass = this.$el.classList.toString();
        this.$el.removeAttribute('class');


        let config = {
            minLength: parseInt(this.minLength || 2),
            highlight: true,
            hint: false,
        };

        let suggestion = (context, options) => {
            let html = '<div>';
            html += '<div>';
            if (context.ville) {
                html += '<small>' + context.ville + '</small>';
            }
            html += '<div class="float-right">' + (context.codePostal ? context.codePostal : '') + '</div>';
            html += '</div>';
            html += '<div><b>' + context.libelle + '</b></div>';

            return html + '</div>';
        };

        let source = {
            source: new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.whitespace,
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                remote: {
                    url: 'url',
                    prepare: (query, settings) => {
                        if (this.readonly || !this.route) {
                            return false;
                        }

                        settings.url = Router.generate(this.route, {...this.routeParams, query});
                        return settings;
                    },
                    cache: false
                },
            }),
            templates: {
                suggestion,
                pending: () => {
                    return '<div class="tt-suggestion text-muted">Recherche en cours…</div>';
                },
                notFound: '<div></div>',
            },
            limit: 400,
            displayKey: 'libelle'
        };


        this.jQueryElement
            .typeahead(config, source)
            .on('typeahead:select', (e, data) => {
                this.$emit('select', data);
            })
        ;
    },
    destroyed() {
        this.jQueryElement.off().typeahead('destroy')
    },
    methods: {
        onBlur() {
            if (this.route === 'ajax.commune.query' && this.value && this.value.length <= 5) {
                Request.fetchJson(Router.generate('ajax.commune.abreviation', {query: this.value})).then(data => {
                    data && data[0] && this.$emit('select', data[0]);
                });
            }
        }
    },
}
</script>