'use strict';

import Vue from "vue";

App.Cti = class {
    constructor() {
        const ctiMenu = document.getElementById('ctiMenu');
        if (ctiMenu) {
            new Vue({
                el: ctiMenu,
                template: '<cti-menu :ctiData="ctiData"></cti-menu>',
                data: {
                    ctiData: JSON.parse(ctiMenu.dataset.data),
                },
            });
        }

        const ctiStatut = document.getElementById('ctiStatut');
        if (ctiStatut) {
            new Vue({
                el: ctiStatut,
                template: '<cti-statut :ctiData="ctiData"></cti-statut>',
                data: {
                    ctiData: JSON.parse(ctiStatut.dataset.data),
                },
            });
        }
    }
}
