<template>
    <b-overlay :class="classes" :style="style" :show="loading" spinner-small rounded :opacity="0.6">
        <form-select v-bind="$attrs" :disabled="disabled" :options="options" :value="value" @input="$emit('input', $event)" @click.stop>
            <template #open-indicator="{ attributes }">
                <i v-bind="attributes" class="fa-solid fa-chevron-down text-dark"></i>
            </template>
            <template #no-options>
                <div class="text-left pl-3">
                    Aucun élément.
                </div>
            </template>
        </form-select>
    </b-overlay>
</template>

<script>
import Request from "../../../utils/Request";

export default {
        props: {
            clear: {
                type: Boolean,
                required: false,
                default: undefined,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            large: {
                type: Boolean,
                required: false,
                default: undefined,
            },
            route: {
                type: String,
                required: true,
            },
            small: {
                type: Boolean,
                required: false,
                default: undefined,
            },
            value: {
                required: false,
                default: null,
            },
            width: {
                required: false,
                default: null,
            },
        },
        data: function () {
            return {
                loading: false,
                options: [],
            };
        },
        computed: {
            style() {
                if (this.width) {
                    return {width: this.width};
                }
            },
            classes() {
                return {'vue-select-small': this.small !== undefined, 'vue-select-large': this.large !== undefined, 'vue-select-clear': this.clear !== undefined};
            },
        },
        methods: {
            load() {
                if (!this.disabled) {
                    this.loading = true;
                    Request.fetchJson(this.route)
                        .then(data => this.options = data)
                        .finally(() => this.loading = false);
                }
            },
        },
        mounted() {
            this.load();
        },
        watch: {
            route() {
                this.load();
            },
        },
    }
</script>