import Vue from 'vue';

Vue.config.productionTip = false;

export default class {
    constructor() {
        new Vue({
            el: '#app',
            delimiters: ['[[', ']]'],
            data: {
                personnelChoices: [],
                personnelCollection: [],
            },
            computed: {
                personnelChoicesFiltered() {
                    return this.personnelChoices.filter(p => !this.personnelCollection.includes(p));
                },
                personnelChoicesGrouped() {
                    let grouped = [];
                    this.personnelChoices.forEach(personnel => {
                        let letter = personnel.libelle.charAt(0);
                        if (!grouped.find(v => v.libelle === letter)) {
                            grouped.push({code: letter, libelle: letter.toUpperCase(), children: []});
                        }
                        grouped[grouped.findIndex(v => v.libelle === letter)].children.push(personnel);
                    });

                    return grouped;
                },
            },
            beforeMount() {
                const data = JSON.parse(this.$el.dataset.data);

                for (let key in data) {
                    this[key] = data[key];
                }
            },
            mounted() {
                this.$el.classList.remove('invisible');
            },
        });
    }
}