<template>
    <trumbowyg
        :config="{
            autogrow: true,
            btns: [
                ['formatting'],
                ['strong', 'em', 'underline', 'del'],
                ['foreColor', 'backColor'],
                ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                ['unorderedList', 'orderedList'],
                ['horizontalRule'],
                ['removeformat'],
            ],
            lang: 'fr',
        }"
        :value="value"
        @input="$emit('input', $event)"
    ></trumbowyg>
</template>

<script>
/** @see https://alex-d.github.io/Trumbowyg/documentation/ */
/** @see https://github.com/ankurk91/vue-trumbowyg */
require('trumbowyg/dist/trumbowyg.min');
require('trumbowyg/dist/langs/fr.min');
require('trumbowyg/dist/plugins/colors/trumbowyg.colors.min');
require('trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css');

import VueTrumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.min.css';

export default {
    props: {
        value: {
            type: String,
            required: false,
            default: null,
        }
    },
    components: {
        'trumbowyg': VueTrumbowyg,
    },
}
</script>
