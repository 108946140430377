import Vue from 'vue';
import Regulation from "./Regulation";

Vue.config.productionTip = false;

export default class {
    constructor() {
        new Regulation();
    }
}
