export default {
    data: {
        modal: null,
    },
    methods: {
        onClickOutsideModal(event) {
            // Les clicks dans les options des v-select ne sont pas considérés à l'intérieur de la modal (le html disparaît avant de trigger le click)
            // Un workaround est de stopper la propogation de l'évènement click dans les v-select.
            // @see assets/js/vue/Components/Form/Select.vue : @click.stop
            if (this.modal && !event.target.classList.contains('global-modal') && !event.target.closest('.global-modal')) {
                this.modal = null;
            }
        },
        onKeyDown(e) {
            if (e.key === 'Escape') {
                if ('new-decision' !== this.modal || !this.newDecisionSaving) {
                    this.modal = null;
                }
            }
        },
    },
    watch: {
        modal(newValue) {
            document.body.removeEventListener('click', this.onClickOutsideModal);
            if (newValue) {
                setTimeout(() => document.body.addEventListener('click', this.onClickOutsideModal), 100);
            }
        },
    },
    beforeMount() {
        window.addEventListener('keydown', this.onKeyDown);
    },
}