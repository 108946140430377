<template>
    <menu-dropdown style="margin-right: 8px;" v-model="open">
        <template #button>
            <div @click="toggle" class="menu-dropdown-btn-item cti-notification">
                <div style=" position:absolute;left:0;top:0;bottom:0;   transform: translate(-50%);     display: flex;
    align-items: center;">
                    <div :class="bubbleClass" style="width: 38px; height: 38px;color:#fff;border-radius: 100%;display: flex; align-items: center;justify-content: center;    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);">
                        <i class="fa-solid fa-pause" v-if="call.isHeld"></i>
                        <i class="fa-solid fa-phone-hangup" v-else-if="call.isCleared"></i>
                        <i :class="'fa-solid '+('incoming' === call.direction ? 'fa-phone-arrow-down-left' : 'fa-phone-arrow-up-right')" v-else></i>
                    </div>
                </div>
                <div class="width-140px">
                    <div class="d-flex justify-content-between">
                        <div class="text-normal text-ellipsis">{{ call.matches.length ? call.matches[0].name : 'Inconnu' }}</div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center font-size-12px">
                        <div>{{ call.remote }}</div>
                        <div class="text-muted pl-1">
                            <span v-if="call.isCleared">Terminé</span>
                            <timer class="text-monospace" v-else :value="call.answerAt || call.callAt" seconds></timer>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #dropdown>
            <div class="menu-dropdown-content-title">
                <h3>
                    <span v-if="call.direction === 'outgoing'">Appel sortant</span>
                    <span v-else>Appel entrant</span>
                </h3>
                <b-button size="sm" variant="success" class="ml-auto" @click="createAppel(call.remote)">
                  <i class="fa-solid fa-plus"></i> Nouveau dossier
                </b-button>
                <b-button v-if="call.isCleared" size="sm" variant="danger" class="ml-1" title="Fermer cet appel" @click="$emit('closeNotification')">
                    <i class="fa-solid fa-times"></i>
                </b-button>
            </div>
            <div class="p-3">
                <div class="row mb-n4" v-if="call.matches.length">
                    <div class="col-6 mb-4" v-for="match in call.matches">
                        <div @click="selectAppel(match.numero)" class="cti-match" title="Sélectionner l'appel">
                            <div class="cti-match-type">{{ match.type }} #{{ match.name }}</div>
                            <div class="cti-match-name " :title="match.description">{{ match.description }}</div>
                            <div v-if="match.telephone1" class="cti-match-description text-muted"><i class="fa-solid fa-fw fa-phone"></i> {{ match.telephone1 }}</div>
                            <div v-if="match.telephone2 && match.telephone1 !== match.telephone2" class="cti-match-description text-muted"><i class="fa-solid fa-fw fa-map-location-dot"></i> {{ match.telephone2 }}</div>
                        </div>
                    </div>
                </div>
                <div class="text-muted" v-else>Aucune correspondance.</div>
            </div>
            <div class="p-3 border-top">
                <div class="text-muted" v-if="call.isCleared">
                    Terminé
                </div>
                <div class="d-flex mx-n1" v-else>
                    <button class="mx-1 flex-1 btn btn-success" @click="answer(call.id)" v-if="call.canAnswer">
                        <i class="fa-solid fa-phone"></i> Répondre
                    </button>
                    <button class="mx-1 flex-1 btn btn-warning" @click="hold(call.id)" v-if="call.canHold">
                        <i class="fa-solid fa-pause"></i> Attente
                    </button>
                    <button class="mx-1 flex-1 btn btn-success" @click="resume(call.id)" v-if="call.canResume">
                        <i class="fa-solid fa-play"></i> Reprendre
                    </button>
                    <button class="mx-1 flex-1 btn btn-danger" @click="hang(call.id)" v-if="call.canHang">
                        <i class="fa-solid fa-phone-hangup"></i> Raccrocher
                    </button>
                </div>
            </div>
        </template>
    </menu-dropdown>
</template>

<script>
import MenuDropdown from '../MenuDropdown.vue';
import Timer from '../Timer.vue';

export default {
  components: {Timer, MenuDropdown},
  data: function () {
    return {
      open: false,
    };
  },
  props: {
    call: {
      type: Object,
      required: true,
    },
  },
  computed: {
    bubbleClass() {
      const classes = [];

      switch (true) {
        case this.call.isOriginated:
          classes.push('call-blue');
          break;
        case this.call.isDelivered:
          classes.push('call-wiggle');
          classes.push('call-blue');
          break;
        case this.call.isHeld:
          classes.push('call-pause');
          classes.push('call-orange');
          break;
        case this.call.isEstablished:
        case this.call.isRetrieved:
          classes.push('call-green');
          break;
        case this.call.isCleared:
          classes.push('call-gray');
          break;
      }

      return classes;
    },
  },
  methods: {
    answer(call) {
      webSocket.publish('cti', {action: 'answer', call});
    },
    hang(call) {
      webSocket.publish('cti', {action: 'hang', call});
    },
    hold(call) {
      webSocket.publish('cti', {action: 'hold', call});
    },
    resume(call) {
      webSocket.publish('cti', {action: 'resume', call});
    },
    toggle() {
      this.open = !this.open;
    },
    selectAppel(numero) {
      document.dispatchEvent(new CustomEvent('app.selectAppel', {detail: {numero: numero}}));
      this.open = false;
    },
    createAppel(numero) {
      document.dispatchEvent(new CustomEvent('app.createAppel', {detail: {numero: numero}}));
      this.open = false;
    },
  },
}
</script>