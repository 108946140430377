global.tiles = (online) => {
    return online ? {
        base: [
            global.tilesProviders().osm,
            global.tilesProviders().osm_human,
            global.tilesProviders().ign,
            global.tilesProviders().ign_inspire,
            global.tilesProviders().satelite,
        ],
        overlay: [
            global.tilesProviders().traffic,
            global.tilesProviders().streets,
            global.tilesProviders().reperes_routiers,
        ]
    } : {
        base: [
            global.tilesProviders().offline
        ],
        overlay: []
    };
};

global.tilesMinZoom = 7;
global.tilesMaxZoom = 19;

global.tilesProviders = (online = true) => {
    return {
        offline: {
            name: 'OSM (Cache Offline)',
            visible: true,
            url: "/rastik/tiles/osm&{s}&{z}&{x}&{y}",
            minZoom: global.tilesMinZoom,
            maxZoom: 16,
        },
        osm: {
            name: 'OSM "Standard"',
            visible: true,
            url: "/rastik/tiles/osm&{s}&{z}&{x}&{y}",
            minZoom: global.tilesMinZoom,
            maxZoom: global.tilesMaxZoom,
        },
        osm_human: {
            name: 'OSM "Humanitaire"',
            visible: false,
            url: "https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
            minZoom: global.tilesMinZoom,
            maxZoom: global.tilesMaxZoom,
        },
        ign: {
            name: 'IGN plan V2',
            visible: false,
            url: "https://data.geopf.fr/wmts" +
                "?SERVICE=WMTS" +
                "&REQUEST=GetTile" +
                "&VERSION=1.0.0" +
                "&LAYER=GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2" +
                "&STYLE=normal" +
                "&FORMAT=image/png" +
                "&TILEMATRIXSET=PM" +
                "&TILEMATRIX={z}" +
                "&TILEROW={y}" +
                "&TILECOL={x}",
            minZoom: global.tilesMinZoom,
            maxZoom: global.tilesMaxZoom,
        },
        ign_inspire: {
            name: 'IGN SCAN 25',
            visible: false,
            url: "https://data.geopf.fr/private/wmts" +
                "?SERVICE=WMTS" +
                "&REQUEST=GetTile" +
                "&VERSION=1.0.0" +
                "&LAYER=GEOGRAPHICALGRIDSYSTEMS.MAPS.SCAN25TOUR" +
                "&STYLE=normal" +
                "&FORMAT=image/jpeg" +
                "&TILEMATRIXSET=PM" +
                "&TILEMATRIX={z}" +
                "&TILEROW={y}" +
                "&TILECOL={x}" +
                "&apikey=ign_scan_ws",
            minZoom: global.tilesMinZoom,
            maxZoom: 16,
        },
        satelite: {
            name: "IGN satellite",
            visible: false,
            url: "https://data.geopf.fr/wmts" +
                '?SERVICE=WMTS' +
                '&REQUEST=GetTile' +
                '&VERSION=1.0.0' +
                '&LAYER=ORTHOIMAGERY.ORTHOPHOTOS' +
                '&STYLE=normal' +
                '&FORMAT=image/jpeg' +
                '&TILEMATRIXSET=PM' +
                '&TILEMATRIX={z}' +
                '&TILEROW={y}' +
                '&TILECOL={x}',
            minZoom: global.tilesMinZoom,
            maxZoom: global.tilesMaxZoom,
        },
        reperes_routiers: {
            id: 'reperes_routiers',
            name: "Repères routiers",
            visible: false,
            url: "https://data.geopf.fr/wmts" +
                '?layer=TRANSPORTNETWORK.COMMONTRANSPORTELEMENTS.MARKERPOST' +
                '&style=normal' +
                '&tilematrixset=PM' +
                '&Service=WMTS' +
                '&Request=GetTile' +
                '&Version=1.0.0' +
                '&Format=image%2Fpng' +
                "&TileMatrix={z}" +
                "&TileCol={x}" +
                "&TileRow={y}",
            minZoom: 10,
            maxZoom: 18,
        },
        traffic: {
            id: "traffic",
            name: "Trafic temps réel",
            visible: false,
            url: "/rastik/tiles/traffic&{s}&{z}&{x}&{y}?forceRefresh={forceRefresh}",
            minZoom: global.tilesMinZoom,
            maxZoom: global.tilesMaxZoom,
            zIndex: 12,
            forceRefresh: false,
        },
        streets: {
            id: "streets",
            name: "Noms des rues",
            visible: false,
            url: "https://data.geopf.fr/wmts" +
                "?Service=WMTS" +
                "&Request=GetTile" +
                "&Version=1.0.0" +
                "&layer=TRANSPORTNETWORKS.ROADS" +
                "&style=normal" +
                "&Format=image/png" +
                "&tilematrixset=PM" +
                "&TileMatrix={z}" +
                "&TileCol={x}" +
                "&TileRow={y}",
            minZoom: global.tilesMinZoom,
            maxZoom: 18,
            zIndex: 10,
        }
    };
};