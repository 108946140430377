<template>
    <v-select
        v-bind="$attrs"
        :class="classes"
        :clearable="clearable"
        :disabled="disabled"
        :filterable="false"
        :label="label || 'libelle'"
        :options="options"
        :style="style"
        :value="value"
        @click.stop
        @input="$emit('input', $event)"
        @search:blur="onBlur"
        @search="onSearch"
    >
        <template #open-indicator="{ attributes }">
            <i v-bind="attributes" class="fa-solid fa-chevron-down text-dark"></i>
        </template>
        <template #spinner="{loading}">
            <b-spinner v-show="loading" small></b-spinner>
        </template>
        <template #option="option">
            <div class="d-flex justify-content-between small text-muted">
                <div class="px-1">{{ optionTopLeft ? option[optionTopLeft] : '' }}</div>
                <div class="px-1">{{ optionTopRight ? option[optionTopRight] : '' }}</div>
            </div>
            <div class="d-flex justify-contents-between">
                <div class="text-muted">{{ optionLeft ? option[optionLeft] : '' }}</div>
                <div class="flex-1 px-1">{{ option[label || 'libelle'] }}</div>
                <div class="text-muted">{{ optionRight ? option[optionRight] : '' }}</div>
            </div>
            <div class="d-flex justify-content-between small text-muted">
                <div class="px-1">{{ optionBottomLeft ? option[optionBottomLeft] : '' }}</div>
                <div class="px-1">{{ optionBottomRight ? option[optionBottomRight] : '' }}</div>
            </div>
        </template>
        <template #no-options>
            <div class="text-left pl-3">
                <template v-if="searchInput.length >= (minimumInput || 3)">
                    Aucun élément.
                </template>
                <template v-else>
                    Tapez au moins {{ minimumInput || 3 }} caractère{{ (minimumInput || 3) > 1 ? 's' : '' }} pour rechercher...
                </template>
            </div>
        </template>
    </v-select>
</template>

<script>
import Router from "../../../utils/Router";
import Request from "../../../utils/Request";

export default {
    props: [
        'clear',
        'clearable',
        'disabled',
        'label',
        'large',
        'minimumInput',
        'optionBottomLeft',
        'optionBottomRight',
        'optionLeft',
        'optionRight',
        'optionTopLeft',
        'optionTopRight',
        'route',
        'routeParams',
        'small',
        'value',
        'width',
    ],
    data: function () {
        return {
            abortController: new AbortController(),
            options: [],
            searchInput: '',
        }
    },
    computed: {
        style() {
            if (this.width) {
                return {width: this.width};
            }
        },
        classes() {
            return {
                'vue-select-small': this.small !== undefined,
                'vue-select-large': this.large !== undefined,
                'vue-select-clear': this.clear !== undefined
            };
        },
    },
    mounted() {
        this.$el.querySelector('.vs__clear').setAttribute('title', 'Vider la sélection');
    },
    methods: {
        onSearch(search, loading) {
            this.searchInput = search;
            if (search.length >= (this.minimumInput || 3)) {
                loading(true);
                this.search(loading, search, this);
            } else {
                this.options = [];
            }
        },
        search: Request.debounce((loading, query, vm) => {
            vm.abortController.abort();
            vm.abortController = new AbortController();
            Request.fetchJson(
                Router.generate(vm.route, {...(vm.routeParams || {}), query}),
                'GET',
                null,
                vm.abortController,
            )
                .then(data => (vm.options = data))
                .finally(() => loading(false));
        }, 350),
        onBlur() {
            if (this.route === 'ajax.commune.query' && this.searchInput && this.searchInput.length <= 5) {
                Request.fetchJson(Router.generate('ajax.commune.abreviation', {query: this.searchInput})).then(data => {
                    data && data[0] && this.$emit('input', data[0]);
                });
            }
        },
    }
}
</script>