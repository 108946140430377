<template>
    <b-overlay :show="searching" spinner-small>
        <div class="d-flex">
            <div class="flex-1">
                <div class="row">
                    <div class="col-6">
                        <div class="row">
                            <div class="col-3">
                                <label class="label-sm pt-1 mb-0">Lat</label>
                            </div>
                            <div class="col-9">
                                <input type="text" class="form-control form-control-sm" v-model="latitude" @change="manualEdit" @input="cleanLatLng()">
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-3">
                                <label class="label-sm pt-1 mb-0">Long</label>
                            </div>
                            <div class="col-9">
                                <input type="text" class="form-control form-control-sm" v-model="longitude" @change="manualEdit" @input="cleanLatLng()">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ml-2">
                <button class="btn btn-info btn-sm" v-show="!customQuery" @click="search" title="Lancer la recherche">
                    <i class="fa-solid fa-magnifying-glass fa-fw"></i>
                </button>
                <button class="btn btn-secondary btn-sm" v-show="customQuery" @click="computeQueryFromEntity();search();" title="Regénérer la phrase de recherche">
                    <i class="fa-solid fa-arrows-rotate fa-fw"></i>
                </button>
                <button class="btn btn-secondary btn-sm" @click="toggleCustomQuery" title="Recherche manuelle">
                    <i class="fa-solid fa-pencil fa-fw"></i>
                </button>
            </div>
        </div>
        <div class="mt-1" v-show="customQuery">
            <div class="d-flex">
                <input type="text" class="form-control form-control-sm flex-1" v-model="query" @keyup.enter="search">
                <button class="btn btn-info btn-sm ml-1" @click="search" title="Lancer la recherche">
                    <i class="fa-solid fa-magnifying-glass fa-fw"></i>
                </button>
            </div>
            <div class="d-flex small mt-1">
                <div class="text-muted py-1 px-2">
                    Source
                </div>
                <div class="flex-1 p-1 mr-1 clickable-div text-center cursor-pointer" :class="source === 'ban' ? 'bg-info-ultralight' : ''">
                    <label class="mb-0 cursor-pointer">
                        <input type="radio" name="source" v-model="source" value="ban" class="mr-1">
                        Base Adresse Nationale
                    </label>
                </div>
                <div class="flex-1 p-1 clickable-div text-center cursor-pointer" :class="source === 'osm' ? 'bg-info-ultralight' : ''">
                    <label class="mb-0 cursor-pointer">
                        <input type="radio" name="source" v-model="source" value="osm" class="mr-1">
                        OpenStreetMap
                    </label>
                </div>
            </div>
        </div>
        <div
            class="btn btn-block btn-outline-secondary text-left mt-1 mb-0 px-2 py-1 d-flex"
            v-for="choice in choices"
            @click="selectGeocodage(choice)"
        >
            <div class="flex-1">
                {{ choice.libelle }}<br>
                <small>
                    <span class="text-muted">{{ choice.codePostal }}</span>
                    {{ choice.commune }}
                </small>
            </div>
            <div class="ml-2 px-1 border-left text-muted">
                <small>
                    Lat<br>
                    Long
                </small>
            </div>
            <div class="ml-1 px-1 text-right width-85px">
                <small>
                    {{ choice.latitude }}<br>
                    {{ choice.longitude }}
                </small>
            </div>
            <div class="pl-2 d-flex border-left align-items-center" v-if="choice.score">
                <span class="badge badge-sm width-30px mt-1" :class="{'badge-success': choice.score >= 70, 'badge-warning': choice.score < 70 && choice.score >= 50, 'badge-danger': choice.score < 50}">{{ choice.score }} %</span>
            </div>
        </div>
        <div class="text-center mt-2 mb-1" v-if="noMatch">
            <span class="border-bottom border-warning px-1">
                <i class="fa-regular fa-circle-exclamation text-warning mr-1"></i>
                Aucun résultat pour l'adresse saisie.
            </span>
        </div>
    </b-overlay>
</template>

<script>
import Request from "../../../utils/Request";

export default {
    props: ['entity'],
    data: function () {
        return {
            searching: false,
            customQuery: false,
            latitude: null,
            longitude: null,
            choices: [],
            query: '',
            noMatch: false,
            source: 'ban',
        }
    },
    mounted() {
        this.reset();
    },
    watch: {
        entity: {
            deep: true,
            handler() {
                this.reset();
            }
        }
    },
    methods: {
        reset() {
            this.latitude = this.entity ? this.entity.latitude : null;
            this.longitude = this.entity ? this.entity.longitude : null;

            this.customQuery = false;
            this.choices = [];

            this.computeQueryFromEntity();
        },
        toggleCustomQuery() {
            if (this.customQuery) {
                this.customQuery = false;
                this.computeQueryFromEntity();
            } else {
                this.customQuery = true;
            }
        },
        computeQueryFromEntity () {
            let adresse = '', commune = '', cp = '';

            if (this.entity) {
                adresse = ((this.entity.noVoie ?? '') + ' ' +  (this.entity.voie ?? this.entity.adresse ?? this.entity.adresse1 ?? '') + ' ' + (this.entity.adresse2 ?? '')).toString().trim();

                if('' === adresse && this.entity.libelle) {
                    adresse = this.entity.libelle.toString().trim();
                }

                if (this.entity.commune && typeof this.entity.commune === 'object') {
                    commune = (this.entity.commune.libelle ?? '').toString().trim();
                    cp = (this.entity.commune.codePostal ?? '').toString().trim();
                } else {
                    commune = (this.entity.commune ?? this.entity.ville ?? '').toString().trim();
                    cp = (this.entity.codePostal ?? '').toString().trim();
                }
            }

            this.$forceUpdate();
            this.query = [adresse, commune, cp].filter(v => !!v).join(' ').trim();
        },
        search() {
            this.searching = true;
            this.noMatch = false;
            this.choices = [];

            if(!this.customQuery) {
                this.computeQueryFromEntity();
            }

            const query = '?' + new URLSearchParams({q: this.query.substring(0, 200)}).toString();

            if (query !== '') {
                if (this.source === 'ban') {
                    Request.fetchJson('https://api-adresse.data.gouv.fr/search/' + query)
                        .then(data => {
                            if(data.features.length) {
                                data.features.forEach(feature => {
                                    this.choices.push({
                                        'libelle': feature.properties.name,
                                        'codePostal': feature.properties.postcode,
                                        'commune': feature.properties.city,
                                        'score': Math.trunc(parseFloat(feature.properties.score) * 100),
                                        'latitude': feature.geometry.coordinates[1],
                                        'longitude': feature.geometry.coordinates[0],
                                    });
                                });
                            } else {
                                this.noMatch = true;
                                setTimeout(() => { this.noMatch = false; }, 2000);
                            }
                        })
                        .finally(() => {
                            this.searching = false;
                        });
                }

                if (this.source === 'osm') {
                    Request.fetchJson('https://nominatim.openstreetmap.org/search.php?q=' + query + '&format=geojson&extratags=1')
                        .then(data => {
                            if (data.features.length) {
                                data.features.forEach(feature => {
                                    this.choices.push({
                                        'libelle': feature.properties.name,
                                        'codePostal': '',
                                        'commune': feature.properties.display_name,
                                        'score': null,
                                        'latitude': feature.geometry.coordinates[1],
                                        'longitude': feature.geometry.coordinates[0],
                                    });
                                });
                            } else {
                                this.noMatch = true;
                                setTimeout(() => {
                                    this.noMatch = false;
                                }, 2000);
                            }
                        })
                        .finally(() => {
                            this.searching = false;
                        });
                }
            }
        },
        selectGeocodage(geocodage) {
            this.choices = [];

            this.latitude = geocodage.latitude;
            this.longitude = geocodage.longitude;

            this.$emit('geocodage', geocodage);
        },
        manualEdit() {
            const geocodage = {
                latitude: this.latitude,
                longitude: this.longitude,
            };

            this.$emit('geocodage', geocodage);
        },
        cleanLatLng() {
            this.longitude = this.longitude ? this.longitude.replaceAll(',', '.').replaceAll(/[a-z]/gi, '') : null;
            this.latitude = this.latitude ? this.latitude.replaceAll(',', '.').replaceAll(/[a-z]/gi, '') : null;
        }
    }
}
</script>