const appeldemande = (value, key, item) => item.demande ? item.demande.libelle : '';
const appelzone = (value, key, item) => item.zone ? item.zone.libelle : '';
const defaultAttr = (value) => value ? { title: value } : {};

module.exports = {
    HORODATAGE_FORMATTER: (value) => value ? value.heure : '',
    HORODATAGE_SORT_FORMATTER: (value) => value ? value.date.split('/').reverse().join('/') + value.heure : '',
    HORODATAGE_ATTR: (value) => value ? { title: value.date + ' ' + value.heure } : {},
    APPEL_DEMANDE: appeldemande,
    APPEL_DEMANDE_ATTR: (value, key, item) => { const libelle = appeldemande(value, key, item); return libelle ? { title: libelle } : {}; },
    APPEL_ZONE: appelzone,
    APPEL_ZONE_ATTR: (value, key, item) => { const libelle = appelzone(value, key, item); return libelle ? { title: libelle } : {}; },
    DEFAULT_ATTR: defaultAttr,
    CLASS_MOYEN_FORMATTER: (value, key, item) => {
        let classes = ['text-nowrap', 'width-1px'];
        if (item.enAttenteDeMoyen) {
            classes.push('text-dark');
            if (item.organisme.estVSAB) {
                classes.push('bg-danger-light');
            } else if (item.organisme.estATSU) {
                classes.push('bg-success-light');
            } else if (item.organisme.estSMUR) {
                classes.push('bg-warning-light');
            } else if (item.organisme.estMG) {
                classes.push('bg-info-light');
            }
        }

        return classes.join(' ');
    },
    CLASS_ORGANISME_FORMATTER: (organisme) => {
        if (organisme.estVSAB) {
            return 'bg-danger-light';
        } else if (organisme.estATSU) {
            return 'bg-success-light';
        } else if (organisme.estSMUR) {
            return 'bg-warning-light';
        } else if (organisme.estMG) {
            return 'bg-info-light';
        }
    },
    MOYEN_ATTR: (value, key, item) => item.enAttenteDeMoyen ? {title: 'En attente de moyen'} : defaultAttr(value),
}