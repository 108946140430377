<template>
  <li class="pl-3 mr-2 d-flex">
    <cti-notification v-for="(call, index) in calls" :key="call.id" :call="call" @closeNotification="calls.splice(index, 1)"></cti-notification>
    <cti-poste :poste="ctiData.poste" :status="status" ref="poste"></cti-poste>
  </li>
</template>

<script>
export default {
  data: function () {
    return {
      calls: [],
      status: 'UNAVAILABLE',
    };
  },
  props: {
    ctiData: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    }
  },
  mounted() {
    webSocket.subscribe('cti', (uri, data) => {
      if (data.status) {
        this.status = data.status;
      }

      if (data.calls && data.calls.length) {
        this.calls.splice(0, this.calls.length, ...data.calls);
      }
    });
  },
  watch: {
    calls() {
      if (this.calls.length > 0) {
        this.$refs.poste.$data.open = false;
      }
    },
  },
}
</script>