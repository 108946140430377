<template>
    <span class="mb-0 d-block">
        <span class="initialism form-error-icon badge badge-danger">Erreur</span>
        <span class="form-error-message" v-html="error"></span>
    </span>
</template>

<script>
    export default {
        props: ['error'],
    }
</script>