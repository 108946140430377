export default function duree(duree, unit, showPlusSign = false) {
    if (null === duree) {
        return null;
    }

    const finalUnit = unit || 'hhmm';

    if ('h' === finalUnit) {
        return (duree > 0 && showPlusSign ? '+' : '') + (duree / 60).toLocaleString('fr-FR', {minimumFractionDigits: 0, maximumFractionDigits: 2});
    } else if('hhmm' === finalUnit) {
        const heure = parseInt(Math.abs(duree) / 60);
        const minute = parseInt(Math.abs(duree) - 60 * heure);

        return (duree < 0 ? '-' : (duree > 0 && showPlusSign ? '+' : '')) + (heure < 10 ? '0' + heure : heure) + ':' + (minute < 10 ? '0' + minute : minute);
    } else if('hhmmss' === finalUnit) {
        const heure = parseInt(Math.abs(duree) / 60 / 60);
        const minute = parseInt((Math.abs(duree) - 60 * 60 * heure) / 60);
        const second = parseInt(Math.abs(duree) - 60 * 60 * heure - 60 * minute);

        return (duree < 0 ? '-' : (duree > 0 && showPlusSign ? '+' : '')) + (heure < 10 ? '0' + heure : heure) + ':' + (minute < 10 ? '0' + minute : minute) + ':' + (second < 10 ? '0' + second : second);
    }

    return duree;
}