<template>
    <div class="h-100">
        <a
            aria-haspopup="true"
            class="nav-link text-white"
            href="javascript:void(0)"
            style="line-height: 47px;"
            title="Voir les nouveautés"
            @click="loadChangelog()"
            v-b-modal.modal-changelog
        >
            <i class="fa-solid fa-sparkles px-2"></i>
        </a>

        <b-modal id="modal-changelog" hide-footer centered class="d-none" size="xl">
            <template #modal-header="{close}">
                <h4 class="mb-0">
                    <i class="fa-solid fa-sparkles text-muted mr-1"></i>
                    Nouveautés
                    <small class="text-muted" v-if="changelog">
                        - Version actuelle :
                        <strong class="text-number">{{ changelog.current }}</strong>
                    </small>
                </h4>
                <button type="button" aria-label="Fermer" class="close text-muted" @click="close()">
                    ×
                </button>
            </template>
            <template #default="{}">
                <b-overlay :show="!changelog">
                    <div class="d-flex">
                        <div class="width-85px text-center">
                            <template v-for="version in Object.keys(changelog)">
                                <div v-if="version !== 'current'">
                                    <div class="p-1 text-number mb-1 position-relative" :class="version === shownVersion ? 'bg-info text-white' : 'clickable-div bg-light border '" :title="version === changelog.current ? 'Version actuelle' : ''" @click="shownVersion = version">
                                        <i class="fa-solid fa-check position-absolute" v-if="version === changelog.current" style="left:7px; top:7px;"></i>
                                        <strong>{{ version }}</strong>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="flex-1 border-left ml-2 px-2" style="height: 800px; overflow-y: scroll;">
                            <template v-for="(modules, version) in changelog">
                                <div v-if="version !== 'current' && version === shownVersion">
                                    <template v-for="(changes, module) in modules">
                                        <div v-if="'Version initiale' === module">
                                            <div class="font-weight-500 px-1 mb-1 text-success">
                                                <i class="fa-solid fa-award-simple fa-fw mr-1"></i>
                                                {{ module }}
                                            </div>
                                            <div class="btn-success mb-1" style="height: 1px;"></div>
                                        </div>
                                        <div v-else-if="'Régulation' === module">
                                            <div class="font-weight-500 px-1 mb-1 text-theme-bleu-roi">
                                                <i class="fa-solid fa-user-headset fa-fw mr-1"></i>
                                                {{ module }}
                                            </div>
                                            <div class="btn-theme-bleu-roi mb-1" style="height: 1px;"></div>
                                        </div>
                                        <div v-else-if="'Cartographie' === module">
                                            <div class="font-weight-500 px-1 mb-1 text-theme-amethyste">
                                                <i class="fa-solid fa-compass fa-fw mr-1"></i>
                                                {{ module }}
                                            </div>
                                            <div class="btn-theme-amethyste mb-1" style="height: 1px;"></div>
                                        </div>
                                        <div v-else-if="'Archives' === module">
                                            <div class="font-weight-500 px-1 mb-1 text-theme-gris-de-payne">
                                                <i class="fa-solid fa-box-archive fa-fw mr-1"></i>
                                                {{ module }}
                                            </div>
                                            <div class="btn-theme-gris-de-payne mb-1" style="height: 1px;"></div>
                                        </div>
                                        <div v-else-if="'Gardes' === module">
                                            <div class="font-weight-500 px-1 mb-1 text-theme-indigo">
                                                <i class="fa-solid fa-candle-holder fa-fw mr-1"></i>
                                                {{ module }}
                                            </div>
                                            <div class="btn-theme-indigo mb-1" style="height: 1px;"></div>
                                        </div>
                                        <div v-else-if="'Paramétrage' === module">
                                            <div class="font-weight-500 px-1 mb-1 text-theme-citrouille">
                                                <i class="fa-solid fa-database fa-fw mr-1"></i>
                                                {{ module }}
                                            </div>
                                            <div class="btn-theme-citrouille mb-1" style="height: 1px;"></div>
                                        </div>
                                        <div v-else-if="'Administration' === module">
                                            <div class="font-weight-500 px-1 mb-1 text-theme-vert-prairie">
                                                <i class="fa-solid fa-users fa-fw mr-1"></i>
                                                {{ module }}
                                            </div>
                                            <div class="btn-theme-vert-prairie mb-1" style="height: 1px;"></div>
                                        </div>
                                        <div v-else-if="'Nouvelles fonctionnalités' === module">
                                            <div class="font-weight-500 px-1 mb-1 text-success">
                                                <i class="fa-solid fa-sparkles fa-fw mr-1"></i>
                                                {{ module }}
                                            </div>
                                            <div class="btn-success mb-1" style="height: 1px;"></div>
                                        </div>
                                        <div v-else-if="'Sécurité' === module">
                                            <div class="font-weight-500 px-1 mb-1 text-theme-gris-de-payne">
                                                <i class="fa-solid fa-shield-halved fa-fw mr-1"></i>
                                                {{ module }}
                                            </div>
                                            <div class="btn-theme-gris-de-payne mb-1" style="height: 1px;"></div>
                                        </div>
                                        <div v-else>
                                            <div class="font-weight-500 px-1 mb-1 text-theme-gris-de-payne">
                                                <i class="fa-solid fa-grid-2 fa-fw mr-1"></i>
                                                {{ module }}
                                            </div>
                                            <div class="btn-theme-gris-de-payne mb-1" style="height: 1px;"></div>
                                        </div>
                                        <div class="delimited-blocks-sm px-2 mb-2">
                                            <div v-for="change in changes">
                                                {{ change }}
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </div>
                    </div>
                </b-overlay>
            </template>
        </b-modal>
    </div>
</template>

<script>
import Request from "../../../utils/Request";
import Router from "../../../utils/Router";

export default {
    data: function () {
        return {
            shownVersion: false,
            changelog: false,
        };
    },
    mounted() {},
    methods: {
        loadChangelog() {
            if(!this.changelog) {
                Request.fetchJson(Router.generate('ajax.changelog')).then(data => {
                    this.changelog = data;

                    this.shownVersion = data.current;
                });
            }
        },
    },
}
</script>