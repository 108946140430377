<template>
    <div class="h-100" :class="{'card-missions': 'Carto' === module}">
        <div class="card m-0" :class="'Carto' !== module ? 'mb-2' : 'mb-1'">
            <div
                class=" bloc-list-mission card-header d-flex justify-content-between align-items-center bg-warning text-white"
                :class="'Regulation' !== module ? 'p-2' : null"
            >
                <span class="text-title">Demandes <span class="small">(<span v-show="totalDemandes !== filteredDemandes.length">{{ filteredDemandes.length }}/</span>{{ totalDemandes }})</span></span>
                <div class="d-flex my-n1 align-items-center">
                    <div class="mr-2">
                        <button
                            type="button"
                            class="btn btn-sm mr-1 p-1"
                            :class="{'btn-light': demandesTypeFilters.length > 0, 'btn-clear': 0 === demandesTypeFilters.length}"
                            :style="{'opacity': !demandesTypeFilters.includes('SMUR') ? 0.5 : 1}"
                            title="SMUR"
                            @click="demandesTypeFilters.includes('SMUR') ? demandesTypeFilters.splice(demandesTypeFilters.findIndex(v => 'SMUR'===v), 1) : demandesTypeFilters.push('SMUR')"
                        >
                            <i class="fa-solid fa-fw fa-ambulance text-warning"></i> {{ filteredByZoneDemandes.filter(m => m.estSMUR).length }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-sm mr-1 p-1"
                            :class="{'btn-light': demandesTypeFilters.length > 0, 'btn-clear': 0 === demandesTypeFilters.length}"
                            :style="{'opacity': !demandesTypeFilters.includes('VSAB') ? 0.5 : 1}"
                            title="Pompiers"
                            @click="demandesTypeFilters.includes('VSAB') ? demandesTypeFilters.splice(demandesTypeFilters.findIndex(v => 'VSAB'===v), 1) : demandesTypeFilters.push('VSAB')"
                        >
                            <i class="fa-solid fa-fw fa-truck text-danger"></i> {{ filteredByZoneDemandes.filter(m => m.estVSAB).length }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-sm mr-1 p-1"
                            :class="{'btn-light': demandesTypeFilters.length > 0, 'btn-clear': 0 === demandesTypeFilters.length}"
                            :style="{'opacity': !demandesTypeFilters.includes('ATSU') ? 0.5 : 1}"
                            title="Ambulance privée"
                            @click="demandesTypeFilters.includes('ATSU') ? demandesTypeFilters.splice(demandesTypeFilters.findIndex(v => 'ATSU'===v), 1) : demandesTypeFilters.push('ATSU')"
                        >
                            <i class="fa-solid fa-fw fa-star-of-life text-success"></i> {{ filteredByZoneDemandes.filter(m => m.estATSU).length }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-sm mr-1 p-1"
                            :class="{'btn-light': demandesTypeFilters.length > 0, 'btn-clear': 0 === demandesTypeFilters.length}"
                            :style="{'opacity': !demandesTypeFilters.includes('MG') ? 0.5 : 1}"
                            title="Médecin Garde"
                            @click="demandesTypeFilters.includes('MG') ? demandesTypeFilters.splice(demandesTypeFilters.findIndex(v => 'MG'===v), 1) : demandesTypeFilters.push('MG')"
                        >
                            <i class="fa-solid fa-fw fa-user-md text-info"></i> {{ filteredByZoneDemandes.filter(m => m.estMG).length }}
                        </button>
                    </div>

                    <treeselect
                        v-model="demandesZoneFilter"
                        class="text-dark"
                        :clear-on-select="true"
                        :clearable="true"
                        :multiple="true"
                        no-results-text="Aucun élément."
                        :normalizer="node => ({id: node.code, label: node.libelle})"
                        open-direction="below"
                        :options="zones"
                        placeholder="Toutes les zones"
                        value-format="object"
                        style="max-width: 240px"
                    ></treeselect>
                    <button v-if="role_appels_view && 'Regulation' === module" type="button" class="btn btn-xs btn-clear ml-2 d-none d-xl-block" @click="$emit('toggleexpanded', 'missions')">
                        <i class="fa-solid fa-lg fa-flip-horizontal" :class="expanded === 'missions' ? 'fa-compress-alt' : 'fa-expand-alt'" :title="expanded === 'missions' ? 'Réduire' : 'Agrandir'"></i>
                        <template v-if="expanded === 'missions'">
                            Afficher les appels
                        </template>
                    </button>
                    <b-button v-else-if="'Carto' === module" class="p-1 width-30px ml-2" @click="$emit('close')">
                        <i class="fa-solid fa-xmark"></i>
                    </b-button>
                </div>
            </div>
            <div class="card-body p-0">
                <b-table
                    class="mb-0"
                    ref="demandesTable"
                    hover
                    show-empty
                    :items="filteredDemandes"
                    :fields="demandesFields.filter(f => !columns || columns.includes(f.key))"
                    :sort-by.sync="demandesSortBy"
                    :sort-desc.sync="demandesSortDesc"
                    :responsive="true"
                    :tbody-tr-class="listTrClass()"
                    small
                    @row-clicked="$emit('selectappel', $event.decision.appel.numero)"
                >
                    <template v-slot:table-busy>
                        <div class="text-center my-2">
                            <b-spinner></b-spinner>
                        </div>
                    </template>
                    <template v-slot:empty="scope">
                        <div class="text-muted text-center">Aucun élément.</div>
                    </template>
                    <template v-slot:cell(decision.appel.numero)="{item}">
                        <span :title="item.decision.appel.numero" class="text-number">{{ item.decision.appel.numero|substring(5, 9) }}</span><span v-if="item.decision.appel.demande && item.decision.appel.demande.entraineRegulationSecondaire" title="Secondaire" class="font-weight-bold">S</span>
                    </template>
                    <template v-slot:cell(gravite)="{item}">
                        <span :class="'badge gravite-' + item.decision.appel.gravite.code" v-if="item.decision.appel.gravite" title="Gravité">{{ item.decision.appel.gravite.libelle }}</span>
                    </template>
                    <template v-slot:cell(decision.appel.affaire._estVerrouille)="{item}">
                        <span v-if="item.decision.appel.affaire._estVerrouille" :title="'Modification en cours par ' + (item.decision.appel.affaire._estVerrouille.identite ? item.decision.appel.affaire._estVerrouille.identite : 'un autre utilisateur') + (item.decision.appel.affaire._estVerrouille.poste ? ' (' + item.decision.appel.affaire._estVerrouille.poste + ')' : '')"><i class="fa-solid fa-lock text-warning"></i></span>
                    </template>
                </b-table>
            </div>
        </div>

        <div class="card m-0">
            <div
                class=" bloc-list-mission card-header d-flex justify-content-between align-items-center bg-info text-white"
                :class="'Regulation' !== module ? 'p-2' : null"
            >
                <span class="text-title">Missions <span class="small">(<span v-show="totalMissions !== filteredMissions.length">{{ filteredMissions.length }}/</span>{{ totalMissions }})</span></span>
                <div class="d-flex my-n1 align-items-center">

                    <div class="mr-2">
                        <button
                            type="button"
                            class="btn btn-sm mr-1 p-1"
                            :class="{'btn-light': missionsTypeFilters.length > 0, 'btn-clear': 0 === missionsTypeFilters.length}"
                            :style="{'opacity': !missionsTypeFilters.includes('SMUR') ? 0.5 : 1}"
                            title="SMUR"
                            @click="missionsTypeFilters.includes('SMUR') ? missionsTypeFilters.splice(missionsTypeFilters.findIndex(v => 'SMUR'===v), 1) : missionsTypeFilters.push('SMUR')"
                        >
                            <i class="fa-solid fa-fw fa-ambulance text-warning"></i> {{ filteredByZoneMissions.filter(m => m.estSMUR).length }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-sm mr-1 p-1"
                            :class="{'btn-light': missionsTypeFilters.length > 0, 'btn-clear': 0 === missionsTypeFilters.length}"
                            :style="{'opacity': !missionsTypeFilters.includes('VSAB') ? 0.5 : 1}"
                            title="Pompiers"
                            @click="missionsTypeFilters.includes('VSAB') ? missionsTypeFilters.splice(missionsTypeFilters.findIndex(v => 'VSAB'===v), 1) : missionsTypeFilters.push('VSAB')"
                        >
                            <i class="fa-solid fa-fw fa-truck text-danger"></i> {{ filteredByZoneMissions.filter(m => m.estVSAB).length }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-sm mr-1 p-1"
                            :class="{'btn-light': missionsTypeFilters.length > 0, 'btn-clear': 0 === missionsTypeFilters.length}"
                            :style="{'opacity': !missionsTypeFilters.includes('ATSU') ? 0.5 : 1}"
                            title="Ambulance privée"
                            @click="missionsTypeFilters.includes('ATSU') ? missionsTypeFilters.splice(missionsTypeFilters.findIndex(v => 'ATSU'===v), 1) : missionsTypeFilters.push('ATSU')"
                        >
                            <i class="fa-solid fa-fw fa-star-of-life text-success"></i> {{ filteredByZoneMissions.filter(m => m.estATSU).length }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-sm mr-1 p-1"
                            :class="{'btn-light': missionsTypeFilters.length > 0, 'btn-clear': 0 === missionsTypeFilters.length}"
                            :style="{'opacity': !missionsTypeFilters.includes('MG') ? 0.5 : 1}"
                            title="Médecin Garde"
                            @click="missionsTypeFilters.includes('MG') ? missionsTypeFilters.splice(missionsTypeFilters.findIndex(v => 'MG'===v), 1) : missionsTypeFilters.push('MG')"
                        >
                            <i class="fa-solid fa-fw fa-user-md text-info"></i> {{ filteredByZoneMissions.filter(m => m.estMG).length }}
                        </button>
                    </div>

                    <treeselect
                        v-model="missionsZoneFilter"
                        class="text-dark"
                        :clear-on-select="true"
                        :clearable="true"
                        :multiple="true"
                        no-results-text="Aucun élément."
                        :normalizer="node => ({id: node.code, label: node.libelle})"
                        open-direction="below"
                        :options="zones"
                        placeholder="Toutes les zones"
                        value-format="object"
                        style="max-width: 240px"
                    ></treeselect>
                </div>
            </div>
            <div class="card-body p-0">
                <b-table
                    class="mb-0"
                    ref="missionsTable"
                    hover
                    show-empty
                    :items="filteredMissions"
                    :fields="missionsFields.filter(f => !columns || columns.includes(f.key))"
                    :sort-by.sync="missionsSortBy"
                    :sort-desc.sync="missionsSortDesc"
                    :responsive="true"
                    :tbody-tr-class="listTrClass()"
                    small
                    @row-clicked="$emit('selectappel', $event.decision.appel.numero)"
                >
                    <template v-slot:table-busy>
                        <div class="text-center my-2">
                            <b-spinner></b-spinner>
                        </div>
                    </template>
                    <template v-slot:empty="scope">
                        <div class="text-muted text-center">Aucun élément.</div>
                    </template>
                    <template v-slot:cell(organisme.type)="{item}">
                        <span :title="item.organisme.type">
                            <i
                                class="fa-solid fa-fw"
                                :class="{
                                    'fa-truck text-danger': item.estVSAB,
                                    'fa-star-of-life text-success': item.estATSU,
                                    'fa-ambulance text-warning': item.estSMUR,
                                    'fa-user-md text-info': item.estMG,
                                    'fa-question text-muted': !item.estVSAB && !item.estATSU && !item.estSMUR && !item.estMG,
                                }"
                            ></i>
                        </span>
                    </template>
                    <template v-slot:cell(decision.appel.numero)="{item}">
                        <span :title="item.decision.appel.numero" class="text-number">{{ item.decision.appel.numero|substring(5, 9) }}</span><span v-if="item.decision.appel.demande && item.decision.appel.demande.entraineRegulationSecondaire" title="Secondaire" class="font-weight-bold">S</span>
                    </template>
                    <template v-slot:cell(gravite)="{item}">
                        <span :class="'badge gravite-' + item.decision.appel.gravite.code" v-if="item.decision.appel.gravite" title="Gravité">{{ item.decision.appel.gravite.libelle }}</span>
                    </template>
                    <template v-slot:cell(topTransmission)="{item}">
                        <div class="top-horaire" :class="{'top-horaire-loading': role_mission_tops_horaires && topLoaders.includes(item.decision.appel.numero + '-' + item.decision.numeroDecision + '-' + item.numeroMission + '-topTransmission')}" @click.stop="onClickTopHoraire(item, 'topTransmission')">
                            <div v-if="role_mission_tops_horaires" class="top-horaire-icon bg-gray"><i class="fa-solid fa-timer"></i></div>
                            <div v-if="role_mission_tops_horaires" class="top-horaire-loader bg-gray"><i class="fa-solid fa-circle-notch fa-spin"></i></div>
                            {{ item.topTransmission ? item.topTransmission.heure : '&nbsp;' }}
                        </div>
                    </template>
                    <template v-slot:cell(topDebutReel)="{item}">
                        <div class="top-horaire" :class="{'top-horaire-loading': role_mission_tops_horaires && topLoaders.includes(item.decision.appel.numero + '-' + item.decision.numeroDecision + '-' + item.numeroMission + '-topDebutReel')}" @click.stop="onClickTopHoraire(item, 'topDebutReel')">
                            <div v-if="role_mission_tops_horaires" class="top-horaire-icon bg-gray"><i class="fa-solid fa-timer"></i></div>
                            <div v-if="role_mission_tops_horaires" class="top-horaire-loader bg-gray"><i class="fa-solid fa-circle-notch fa-spin"></i></div>
                            {{ item.topDebutReel ? item.topDebutReel.heure : '&nbsp;' }}
                        </div>
                    </template>
                    <template v-slot:cell(topPriseEnChargeArriveeReel)="{item}">
                        <div class="top-horaire" :class="{'top-horaire-loading': role_mission_tops_horaires && topLoaders.includes(item.decision.appel.numero + '-' + item.decision.numeroDecision + '-' + item.numeroMission + '-topPriseEnChargeArriveeReel')}" @click.stop="onClickTopHoraire(item, 'topPriseEnChargeArriveeReel')">
                            <div v-if="role_mission_tops_horaires" class="top-horaire-icon bg-gray"><i class="fa-solid fa-timer"></i></div>
                            <div v-if="role_mission_tops_horaires" class="top-horaire-loader bg-gray"><i class="fa-solid fa-circle-notch fa-spin"></i></div>
                            {{ item.topPriseEnChargeArriveeReel ? item.topPriseEnChargeArriveeReel.heure : '&nbsp;' }}
                        </div>
                    </template>
                    <template v-slot:cell(bilan)="{item}">
                        {{ item.decision.appel.bilan && item.decision.appel.bilan.horodatage ? item.decision.appel.bilan.horodatage.heure : '' }}
                    </template>
                    <template v-slot:cell(topPriseEnChargeDepartReel)="{item}">
                        <div class="top-horaire" :class="{'top-horaire-loading': role_mission_tops_horaires && topLoaders.includes(item.decision.appel.numero + '-' + item.decision.numeroDecision + '-' + item.numeroMission + '-topPriseEnChargeDepartReel')}" @click.stop="onClickTopHoraire(item, 'topPriseEnChargeDepartReel')">
                            <div v-if="role_mission_tops_horaires" class="top-horaire-icon bg-gray"><i class="fa-solid fa-timer"></i></div>
                            <div v-if="role_mission_tops_horaires" class="top-horaire-loader bg-gray"><i class="fa-solid fa-circle-notch fa-spin"></i></div>
                            {{ item.topPriseEnChargeDepartReel ? item.topPriseEnChargeDepartReel.heure : '&nbsp;' }}
                        </div>
                    </template>
                    <template v-slot:cell(_metaDestination1)="{item}">
                        <div class="delimited-blocks-sm">
                            <div v-for="etablissement in item._metaDestination1" class="text-ellipsis">
                                {{ etablissement || '&nbsp;' }}
                            </div>
                        </div>
                    </template>
                    <template v-slot:cell(_metaDestination2)="{item}">
                        <div class="delimited-blocks-sm">
                            <div v-for="service in item._metaDestination2" class="text-ellipsis">
                                {{ service || '&nbsp' }}
                            </div>
                        </div>
                    </template>
                    <template v-slot:cell(topDestinationArriveeReel)="{item}">
                        <template v-if="item.decision.appel.demande && item.decision.appel.demande.entraineRegulationSecondaire">
                            <div v-if="item.decision.appel.secondaire" class="top-horaire" :class="{'top-horaire-loading': role_mission_tops_horaires && topLoaders.includes(item.decision.appel.numero + '-' + item.decision.numeroDecision + '-' + item.numeroMission + '-topDestinationArriveeReel')}" @click.stop="onClickTopHoraire(item, 'topDestinationArriveeReel')">
                                <div v-if="role_mission_tops_horaires" class="top-horaire-icon bg-gray"><i class="fa-solid fa-timer"></i></div>
                                <div v-if="role_mission_tops_horaires" class="top-horaire-loader bg-gray"><i class="fa-solid fa-circle-notch fa-spin"></i></div>
                                {{ item.decision.appel.secondaire.topDestinationArriveeReel ? item.decision.appel.secondaire.topDestinationArriveeReel.heure : '&nbsp;' }}
                            </div>
                        </template>
                        <div v-else class="delimited-blocks-sm">
                            <div v-for="(devenir, index) in item.devenirData" class="top-horaire" :class="{'top-horaire-loading': role_mission_tops_horaires && topLoaders.includes(item.decision.appel.numero + '-' + item.decision.numeroDecision + '-' + item.numeroMission + '-topDestinationArriveeReel-' + index)}" @click.stop="onClickTopHoraire(item, 'topDestinationArriveeReel', index)">
                                <div v-if="role_mission_tops_horaires" class="top-horaire-icon bg-gray"><i class="fa-solid fa-timer"></i></div>
                                <div v-if="role_mission_tops_horaires" class="top-horaire-loader bg-gray"><i class="fa-solid fa-circle-notch fa-spin"></i></div>
                                {{ devenir.topDestinationArriveeReel && devenir.topDestinationArriveeReel.heure ? devenir.topDestinationArriveeReel.heure : '&nbsp;' }}
                            </div>
                        </div>
                    </template>
                    <template v-slot:cell(topFinMedicalisationReel)="{item}">
                        <div class="top-horaire" :class="{'top-horaire-loading': role_mission_tops_horaires && topLoaders.includes(item.decision.appel.numero + '-' + item.decision.numeroDecision + '-' + item.numeroMission + '-topFinMedicalisationReel')}" @click.stop="onClickTopHoraire(item, 'topFinMedicalisationReel')">
                            <div v-if="role_mission_tops_horaires" class="top-horaire-icon bg-gray"><i class="fa-solid fa-timer"></i></div>
                            <div v-if="role_mission_tops_horaires" class="top-horaire-loader bg-gray"><i class="fa-solid fa-circle-notch fa-spin"></i></div>
                            {{ item.topFinMedicalisationReel ? item.topFinMedicalisationReel.heure : '&nbsp;' }}
                        </div>
                    </template>
                    <template v-slot:cell(topFinReel)="{item}">
                        <div class="top-horaire" :class="{'top-horaire-loading': role_mission_tops_horaires && topLoaders.includes(item.decision.appel.numero + '-' + item.decision.numeroDecision + '-' + item.numeroMission + '-topFinReel')}" @click.stop="onClickTopHoraire(item, 'topFinReel')">
                            <div v-if="role_mission_tops_horaires" class="top-horaire-icon bg-gray"><i class="fa-solid fa-timer"></i></div>
                            <div v-if="role_mission_tops_horaires" class="top-horaire-loader bg-gray"><i class="fa-solid fa-circle-notch fa-spin"></i></div>
                            {{ item.topFinReel ? item.topFinReel.heure : '&nbsp;' }}
                        </div>
                    </template>
                    <template v-slot:cell(decision.appel.affaire._estVerrouille)="{item}">
                        <span v-if="item.decision.appel.affaire._estVerrouille" :title="'Modification en cours par ' + (item.decision.appel.affaire._estVerrouille.identite ? item.decision.appel.affaire._estVerrouille.identite : 'un autre utilisateur') + (item.decision.appel.affaire._estVerrouille.poste ? ' (' + item.decision.appel.affaire._estVerrouille.poste + ')' : '')"><i class="fa-solid fa-lock text-warning"></i></span>
                    </template>
                </b-table>
            </div>
        </div>

        <b-modal
            v-if="editTop.mission"
            id="modal-mission-top-horaire"
            centered
            header-bg-variant="info"
            header-text-variant="white"
            title="Modifier un top horaire"
            v-model="editTop.modalShow"
            @ok.prevent="saveEditTop()"
        >
            <div class="row">
                <div class="col-6">
                    <div class="row view-row no-border">
                        <div class="col-4 view-label">Appel</div>
                        <div class="col-8">{{ editTop.mission.decision.appel.numero }}</div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row view-row no-border">
                        <div class="col-4 view-label">Date</div>
                        <div class="col-8">{{ editTop.mission.decision.appel.horodatage.date }} {{ editTop.mission.decision.appel.horodatage.heure }}</div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row view-row">
                        <div class="col-4 view-label">Pec</div>
                        <div class="col-8">{{ editTop.mission._metaPriseEnCharge1 }}</div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row view-row">
                        <div class="col-4 view-label">Adresse</div>
                        <div class="col-8">{{ editTop.mission._metaPriseEnCharge2 }}</div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row view-row">
                        <div class="col-4 view-label">Mission</div>
                        <div class="col-8">{{ editTop.mission._metaInfo1 }}</div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row view-row">
                        <div class="col-4 view-label">Moyen</div>
                        <div class="col-8">{{ editTop.mission._metaInfo2 }}</div>
                    </div>
                </div>
                <template v-if="editTop.indexDevenir !== null">
                    <div class="col-6">
                        <div class="row view-row">
                            <div class="col-4 view-label">Dest.</div>
                            <div class="col-8">{{ editTop.mission.devenirData[editTop.indexDevenir].etablissement }}</div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row view-row">
                            <div class="col-4 view-label">Service</div>
                            <div class="col-8">{{ editTop.mission.devenirData[editTop.indexDevenir].service }}</div>
                        </div>
                    </div>
                </template>
            </div>

            <hr>

            <form-error :errors="editTop.errors['']" root></form-error>
            <b-form-group class="mb-0" :label="missionsFields.find(m => m.key === editTop.field).headerTitle" label-cols="4">
                <b-form-input v-model="editTop.oldTop" class="text-center" readonly></b-form-input>
                <div class="w-100 py-1 text-center">
                    <i class="fa-solid fa-chevron-down text-muted"></i>
                </div>
                <b-input-group>
                    <b-input-group-prepend>
                        <b-button :disabled="!editTop.newTop" size="sm" @click="alterTop(-5)">-5</b-button>
                    </b-input-group-prepend>
                    <b-input-group-append>
                        <b-button class="border-right-0" :disabled="!editTop.newTop" size="sm" @click="alterTop(-1)">-1</b-button>
                    </b-input-group-append>
                    <div class="flex-1">
                        <vue-ctk-date-time-picker
                            v-model="editTop.newTop"
                            button-now-translation="Maintenant"
                            format="DD/MM/YYYY HH:mm"
                            formatted="DD/MM/YY HH:mm"
                            id="editTop-datepicker"
                            label="Nouvelle date"
                            no-header
                            no-label
                            @input="editTop.errors = {}"
                        ></vue-ctk-date-time-picker>
                    </div>
                    <b-input-group-append>
                        <b-button :disabled="!editTop.newTop" size="sm" @click="alterTop(1)">+1</b-button>
                    </b-input-group-append>
                    <b-input-group-append>
                        <b-button :disabled="!editTop.newTop" size="sm" @click="alterTop(5)">+5</b-button>
                    </b-input-group-append>
                </b-input-group>
                <form-error :errors="editTop.errors.top"></form-error>
                <div
                    class="small text-center"
                    :class="{
                        'text-muted': Math.abs(editTopDiffInMinutes) <= 60,
                        'text-warning': Math.abs(editTopDiffInMinutes) > 60,
                        'invisible': !editTopDiffInMinutes,
                    }">
                    {{ editTopDiffInMinutes > 0 ? '+' : '' }}{{ editTopDiffInMinutes }} minute{{ Math.abs(editTopDiffInMinutes) > 1 ? 's' : '' }}
                </div>
            </b-form-group>

            <template v-slot:modal-footer="{ ok, cancel }">
                <b-overlay :show="topLoaders.includes(editTop.mission.decision.appel.numero + '-' + editTop.mission.decision.numeroDecision + '-' + editTop.mission.numeroMission + '-' + editTop.field)" spinner-small rounded :opacity="0.6" class="w-100 d-flex justify-content-between">
                    <b-button variant="outline-danger" @click="deleteTop()">Supprimer</b-button>
                    <div>
                        <b-button @click="cancel()">Annuler</b-button>
                        <b-button variant="outline-info" @click="ok()">Modifier</b-button>
                    </div>
                </b-overlay>
            </template>
        </b-modal>
    </div>
</template>

<script>
import {
    CLASS_MOYEN_FORMATTER,
    DEFAULT_ATTR,
    HORODATAGE_ATTR,
    HORODATAGE_FORMATTER,
    HORODATAGE_SORT_FORMATTER,
    MOYEN_ATTR
} from "../../../app/BootstrapVueHelpers";
import Router from '../../../utils/Router';
import Request from '../../../utils/Request';
import Vue from "vue";
import moment from "moment";

export default {
    data: function () {
        return {
            demandesFields: [
                { key: 'decision.appel.numero', label: '#', headerTitle: 'Numéro', tdClass: 'width-1px', sortable: true },
                { key: 'decision.appel.horodatage', label: 'HAp', headerTitle: 'Heure d\'appel', tdClass: 'text-number width-1px', sortable: true, sortByFormatted: HORODATAGE_SORT_FORMATTER, formatter: HORODATAGE_FORMATTER, tdAttr: HORODATAGE_ATTR },
                { key: 'gravite', label: '', tdClass: 'width-1px', sortable: true },
                { key: '_metaInfo2', label: 'Demande', tdClass: CLASS_MOYEN_FORMATTER, sortable: true, tdAttr: MOYEN_ATTR },
                { key: 'organisme.libelle', label: 'Organisme', sortable: true, tdAttr: DEFAULT_ATTR, tdClass: 'text-nowrap width-1px' },
                { key: 'decision.appel.typeEvenement.libelle', label: 'Motif', headerTitle: 'Motif', tdClass: 'text-ellipsis', thStyle: { 'min-width': '75px' }, sortable: true, tdAttr: DEFAULT_ATTR },
                { key: '_metaPriseEnCharge1', searchKey: 'lol', label: 'Lieu', headerTitle: 'Lieu', tdClass: 'text-ellipsis', thStyle: { 'min-width': '75px' }, sortable: true, tdAttr: DEFAULT_ATTR },
                { key: '_metaPriseEnCharge2', label: 'Adresse', headerTitle: 'Prise en charge', tdClass: 'text-ellipsis', thStyle: { 'min-width': '50px' }, sortable: true, tdAttr: DEFAULT_ATTR },
                { key: 'decision.appel.zone.libelle', label: 'Zone', headerTitle: 'Zone', tdClass: 'text-ellipsis', thStyle: { 'min-width': '50px' }, sortable: true, tdAttr: DEFAULT_ATTR },
                { key: 'decision.appel.affaire._estVerrouille', label: '', tdClass: 'width-1px' },
            ],
            missionsFields: [
                { key: 'organisme.type', label: '', tdClass: 'width-1px', sortable: true },
                { key: 'decision.appel.numero', label: '#', headerTitle: 'Numéro', tdClass: 'width-1px', sortable: true },
                { key: 'decision.appel.horodatage', label: 'HAp', headerTitle: 'Heure d\'appel', tdClass: 'text-number width-1px', sortable: true, sortByFormatted: HORODATAGE_SORT_FORMATTER, formatter: HORODATAGE_FORMATTER, tdAttr: HORODATAGE_ATTR },
                { key: 'gravite', label: '', tdClass: 'width-1px', sortable: true },
                { key: '_metaPriseEnCharge1', searchKey: 'lol', label: 'Pec', headerTitle: 'Prise en charge', tdClass: 'text-ellipsis', thStyle: { 'min-width': '75px' }, sortable: true, tdAttr: DEFAULT_ATTR },
                { key: '_metaPriseEnCharge2', label: 'Adresse', headerTitle: 'Prise en charge', tdClass: 'text-ellipsis', thStyle: { 'min-width': '50px' }, sortable: true, tdAttr: DEFAULT_ATTR },
                { key: '_metaInfo1', label: 'Mission', tdClass: 'text-nowrap width-1px', sortable: true, tdAttr: DEFAULT_ATTR },
                { key: '_metaInfo2', label: 'Moyen', tdClass: CLASS_MOYEN_FORMATTER, sortable: true, tdAttr: MOYEN_ATTR },
                { key: 'topTransmission', label: 'Htr', headerTitle: 'Heure de transmission', tdClass: 'text-number width-1px', sortable: true, sortByFormatted: HORODATAGE_SORT_FORMATTER, formatter: HORODATAGE_FORMATTER, tdAttr: HORODATAGE_ATTR },
                { key: 'topDebutReel', label: 'DpB', headerTitle: 'Départ base', tdClass: 'text-number width-1px', sortable: true, sortByFormatted: HORODATAGE_SORT_FORMATTER, formatter: HORODATAGE_FORMATTER, tdAttr: HORODATAGE_ATTR },
                { key: 'topPriseEnChargeArriveeReel', label: 'ArrL', headerTitle: 'Arrivée sur le lieu', tdClass: 'text-number width-1px', sortable: true, sortByFormatted: HORODATAGE_SORT_FORMATTER, formatter: HORODATAGE_FORMATTER, tdAttr: HORODATAGE_ATTR },
                { key: 'bilan', label: 'Hbil', headerTitle: 'Heure premier bilan', tdClass: 'text-number width-1px', sortable: true, sortByFormatted: HORODATAGE_SORT_FORMATTER, formatter: HORODATAGE_FORMATTER, tdAttr: (value, key, item) => item.decision.appel.bilan && item.decision.appel.bilan.horodatage ? { title: item.decision.appel.bilan.horodatage.date + ' ' + item.decision.appel.bilan.horodatage.heure } : {} },
                { key: 'topPriseEnChargeDepartReel', label: 'DepL', headerTitle: 'Départ du lieu', tdClass: 'text-number width-1px', sortable: true, sortByFormatted: HORODATAGE_SORT_FORMATTER, formatter: HORODATAGE_FORMATTER, tdAttr: HORODATAGE_ATTR },
                { key: '_metaDestination1', label: 'Dest', headerTitle: 'Destination', tdClass: 'text-ellipsis', thStyle: { 'min-width': '75px' }, sortable: true, tdAttr: DEFAULT_ATTR },
                { key: '_metaDestination2', label: '', headerTitle: 'Destination', tdClass: 'text-ellipsis', thStyle: { 'min-width': '50px' }, sortable: true, tdAttr: DEFAULT_ATTR },
                { key: 'topDestinationArriveeReel', label: 'ArrD', headerTitle: 'Arrivée à destination', tdClass: 'text-number width-1px', sortable: true, sortByFormatted: HORODATAGE_SORT_FORMATTER, formatter: HORODATAGE_FORMATTER, tdAttr: HORODATAGE_ATTR },
                { key: 'topFinMedicalisationReel', label: 'FMed', headerTitle: 'Fin de médicalisation', tdClass: 'text-number width-1px', sortable: true, sortByFormatted: HORODATAGE_SORT_FORMATTER, formatter: HORODATAGE_FORMATTER, tdAttr: HORODATAGE_ATTR },
                { key: 'topFinReel', label: 'FMis', headerTitle: 'Fin de mission', tdClass: 'text-number width-1px', sortable: true, sortByFormatted: HORODATAGE_SORT_FORMATTER, formatter: HORODATAGE_FORMATTER, tdAttr: HORODATAGE_ATTR },
                { key: 'decision.appel.affaire._estVerrouille', label: '', tdClass: 'width-1px' },
            ],
            demandesZoneFilter: [],
            demandesTypeFilters: ['VSAB', 'ATSU', 'SMUR', 'MG'],
            demandesSortBy: 'decision.appel.horodatage',
            demandesSortDesc: false,
            editTop: {
                mission: null,
                indexDevenir: null,
                oldTop: null,
                field: null,
                newTop: null,
                modalShow: false,
                errors: {},
            },
            missionsZoneFilter: [],
            missionsTypeFilters: ['VSAB', 'ATSU', 'SMUR', 'MG'],
            missionsSortBy: 'decision.appel.horodatage',
            missionsSortDesc: false,
            topLoaders: [],
        };
    },
    props: {
        canChangeAppel: {
            type: Boolean,
            required: false,
            default: true,
        },
        columns: {
            type: Array,
            required: false,
            default: null,
        },
        csrf: {
            type: Object,
            required: false,
            default: () => ({topHoraire: ''}),
        },
        currentAppelNumero: {
            type: String,
            required: false,
            default: null,
        },
        expanded: {
            type: String,
            required: false,
            default: null,
        },
        missions: {
            type: Array,
            required: true,
        },
        module: {
            type: String,
            required: true,
        },
        role_appels_view: {
            type: Boolean,
            required: false,
            default: true,
        },
        role_mission_tops_horaires: {
            type: Boolean,
            required: true,
        },
        zones: {
            type: Array,
            required: true,
        },
        zonesParDefaut: {
            type: Array,
            required: true,
        },
    },
    computed: {
        totalDemandes() {
            return this.missions.filter((mission) => mission.enAttenteDeMoyen).length;
        },
        filteredDemandes() {
            return this.filteredByZoneDemandes.filter((mission) => {
                return (mission.estVSAB && this.demandesTypeFilters.includes('VSAB'))
                    || (mission.estATSU && this.demandesTypeFilters.includes('ATSU'))
                    || (mission.estSMUR && this.demandesTypeFilters.includes('SMUR'))
                    || ((!mission.organisme || (!mission.estVSAB && !mission.estATSU && !mission.estSMUR)) && this.demandesTypeFilters.includes('MG'))
                ;
            });
        },
        filteredByZoneDemandes() {
            return this.missions.filter((mission) => {
                return mission.enAttenteDeMoyen
                    && (
                        0 === this.demandesZoneFilter.length
                        || (
                            mission.decision.appel.zone
                            && this.demandesZoneFilter.some(zone => zone.code === mission.decision.appel.zone.code)
                        )
                    )
                ;
            });
        },
        totalMissions() {
            return this.missions.filter((mission) => !mission.enAttenteDeMoyen).length;
        },
        filteredMissions() {
            return this.filteredByZoneMissions.filter((mission) => {
                return (mission.estVSAB && this.missionsTypeFilters.includes('VSAB'))
                    || (mission.estATSU && this.missionsTypeFilters.includes('ATSU'))
                    || (mission.estSMUR && this.missionsTypeFilters.includes('SMUR'))
                    || ((!mission.organisme || (!mission.estVSAB && !mission.estATSU && !mission.estSMUR)) && this.missionsTypeFilters.includes('MG'))
                ;
            });
        },
        filteredByZoneMissions() {
            return this.missions.filter((mission) => {
                return !mission.enAttenteDeMoyen
                    && (
                        0 === this.missionsZoneFilter.length
                        || (
                            mission.decision.appel.zone
                            && this.missionsZoneFilter.some(zone => zone.code === mission.decision.appel.zone.code)
                        )
                    )
                ;
            });
        },
        editTopDiffInMinutes() {
            if (
                this.editTop.oldTop
                && this.editTop.newTop
                && this.editTop.mission
            ) {
                const mOld = moment(this.editTop.oldTop, 'DD/MM/YYYY HH:mm');
                const mNew = moment(this.editTop.newTop, 'DD/MM/YYYY HH:mm');
                if (mOld.isValid() && mNew.isValid() && !mOld.isSame(mNew)) {
                    return mNew.diff(mOld, 'minutes', false);
                }
            }

            return null;
        }
    },
    methods: {
        listTrClass() {
            return (item) => {
                let classes = [];

                if (item) {
                    if (this.canChangeAppel) {
                        classes.push('cursor-pointer');
                    }
                    if (this.currentAppelNumero && item && this.currentAppelNumero === item.decision.appel.numero) {
                        classes.push('bg-gray');
                    }
                }

                return classes;
            };
        },
        updateTopHoraire: function (mission, field, date, indexDevenir) {
            let loaderId = mission.decision.appel.numero + '-' + mission.decision.numeroDecision + '-' + mission.numeroMission + '-' + field;
            if (indexDevenir !== null && typeof indexDevenir !== 'undefined') {
                loaderId += '-' + indexDevenir;
            }
            this.topLoaders.push(loaderId);

            Request.fetchJson(
                Router.generate('appel.decision.mission.top-horaire', {
                    appel: mission.decision.appel.numero,
                    decision: mission.decision.numeroDecision,
                    mission: mission.numeroMission,
                    numeroPatient: indexDevenir !== null && typeof indexDevenir !== 'undefined' ? mission.devenirData[indexDevenir].numeroPatient : null,
                    numeroDevenir: indexDevenir !== null && typeof indexDevenir !== 'undefined' ? mission.devenirData[indexDevenir].numeroDevenir : null,
                }),
                'PATCH',
                {
                    top_horaire: {
                        _token: this.csrf.topHoraire,
                        field,
                        top: date ? new Intl.DateTimeFormat('fr', {
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric'
                        }).format(date) : null,
                    }
                }
            ).then(response => {
                if (response.success) {
                    this.$emit('tophoraire', {
                        numeroAppel: mission.decision.appel.numero,
                        numeroDecision: mission.decision.numeroDecision,
                        numeroMission: mission.numeroMission,
                        field,
                        indexDevenir,
                        top: date,
                        estSecondaire: !!(mission.decision.appel.demande && mission.decision.appel.demande.entraineRegulationSecondaire),
                    });
                    Vue.set(this.editTop, 'modalShow', false);
                    Vue.set(this.editTop, 'mission', null);
                    Vue.set(this.editTop, 'indexDevenir', null);
                    Vue.set(this.editTop, 'oldTop', null);
                    Vue.set(this.editTop, 'field', null);
                    Vue.set(this.editTop, 'newTop', null);
                    Vue.set(this.editTop, 'errors', {});

                    if (response.topIncoherent) {
                        document.dispatchEvent(new CustomEvent('app.flash', {detail: {type: 'warning', flash: 'La chronologie n\'est pas cohérente !'}}));
                    }
                } else if (this.editTop.mission) {
                    if (response.errors && response.errors.top) {
                        Vue.set(this.editTop, 'errors', response.errors);
                    } else {
                        Vue.set(this.editTop, 'errors', {'': ['Le top horaire n\'a pas pu être enregistré.']});
                    }
                } else {
                    document.dispatchEvent(new CustomEvent('app.flash', {
                        detail: {
                            type: 'error',
                            flash: 'Le top horaire n\'a pas pu être enregistré.'
                        }
                    }));
                }
            }).finally(() => {
                this.topLoaders = this.topLoaders.filter(i => i !== loaderId);
            });
        },
        onClickTopHoraire(mission, field, indexDevenir = null) {
            if (!this.role_mission_tops_horaires) {
                this.$emit('selectappel', mission.decision.appel.numero);
                return;
            }

            const estSecondaire = !!(mission.decision.appel.demande && mission.decision.appel.demande.entraineRegulationSecondaire);
            const getExisting = (mission, field, indexDevenir) => mission[field]
                || (
                    'topDestinationArriveeReel' === field
                    && estSecondaire
                    && mission.decision.appel.secondaire
                    && mission.decision.appel.secondaire.topDestinationArriveeReel
                ) || (
                    'topDestinationArriveeReel' === field
                    && !estSecondaire
                    && null !== indexDevenir
                    && mission.devenirData[indexDevenir].topDestinationArriveeReel
                )
            ;

            if (!getExisting(mission, field, indexDevenir)) {
                const fields = [
                    'topTransmission',
                    'topDebutReel',
                    'topPriseEnChargeArriveeReel',
                    'topPriseEnChargeDepartReel',
                    'topDestinationArriveeReel',
                    'topFinMedicalisationReel',
                    'topFinReel',
                ];
                const indexCurrentTop = fields.findIndex(v => v === field);
                let top = new Date();
                top.setSeconds(0);
                top.setMilliseconds(0);
                // Si un top suivant existe, prend sa valeur
                for (let i = 0; i < fields.length; i++) {
                    const nbRows = 'topDestinationArriveeReel' === fields[i] && !estSecondaire ? mission.devenirData.length : 1;
                    for (let j = 0; j < nbRows; j++) {
                        const existing = getExisting(mission, fields[i], j);
                        if (existing && i > indexCurrentTop) {
                            const topFuture = moment(existing.date + ' ' + existing.heure, 'DD/MM/YYYY HH:mm').toDate();
                            if (top > topFuture) {
                                top = topFuture;
                            }
                        }
                    }
                }
                if ('topFinReel' === field) {
                    App.Layout.confirmDialog(
                        answer => answer && this.updateTopHoraire(mission, field, top, indexDevenir),
                        'Êtes-vous sûr(e) de vouloir mettre fin à la mission ?',
                        'info'
                    );
                } else {
                    this.updateTopHoraire(mission, field, top, indexDevenir);
                }
            } else {
                this.openEditTop(mission, field, indexDevenir);
            }
        },
        openEditTop(mission, field, indexDevenir) {
            let oldTop;
            if ('topDestinationArriveeReel' === field && mission.decision.appel.secondaire && mission.decision.appel.secondaire.topDestinationArriveeReel) {
                oldTop = mission.decision.appel.secondaire.topDestinationArriveeReel.date + ' ' + mission.decision.appel.secondaire.topDestinationArriveeReel.heure;
            } else if ('topDestinationArriveeReel' === field && null !== indexDevenir && mission.devenirData[indexDevenir].topDestinationArriveeReel) {
                oldTop = mission.devenirData[indexDevenir].topDestinationArriveeReel.date + ' ' + mission.devenirData[indexDevenir].topDestinationArriveeReel.heure;
            } else {
                oldTop = mission[field] && mission[field].date ? mission[field].date + ' ' + mission[field].heure : null;
            }
            Vue.set(this.editTop, 'mission', mission);
            Vue.set(this.editTop, 'indexDevenir', indexDevenir);
            Vue.set(this.editTop, 'oldTop', oldTop);
            Vue.set(this.editTop, 'field', field);
            Vue.set(this.editTop, 'newTop', oldTop);
            Vue.set(this.editTop, 'errors', {});
            Vue.set(this.editTop, 'modalShow', true);
        },
        saveEditTop() {
            const date = this.editTop.newTop ? moment(this.editTop.newTop, 'DD/MM/YYYY HH:mm') : null;
            if (null === date || date.isValid()) {
                this.updateTopHoraire(this.editTop.mission, this.editTop.field, date ? date.toDate() : null, this.editTop.indexDevenir);
            } else {
                Vue.set(this.editTop, 'errors', {top: ['Date invalide.']});
            }
        },
        deleteTop() {
            Vue.set(this.editTop, 'newTop', null);
            this.saveEditTop();
        },
        alterTop(value) {
            const newTop = moment(this.editTop.newTop, 'DD/MM/YYYY HH:mm');
            if (newTop.isValid()) {
                Vue.set(this.editTop, 'newTop', newTop.add(value, 'minutes').format('DD/MM/YYYY HH:mm'));
                Vue.set(this.editTop, 'errors', {});
            }
        },
    },
    beforeMount() {
        for (let code of this.zonesParDefaut) {
            let zone = this.zones.find(z => z.code === code);
            zone && this.demandesZoneFilter.push(zone);
            zone && this.missionsZoneFilter.push(zone);
        }
    },
}
</script>

<style>
.top-horaire {
    position: relative;

    .top-horaire-icon, .top-horaire-loader {
        display: none;
        position: absolute;
        text-align: center;
        width: 100%;
    }
    &.top-horaire-loading {
        .top-horaire-loader {
            display: block;
        }
    }
    &:hover:not(.top-horaire-loading) {
        .top-horaire-icon {
            display: block;
        }
    }
}
#editTop-datepicker-input {
    border-radius: 0 !important;
    text-align: center;
}
</style>