<template>
    <div>
        <slot v-if="ready"></slot>
    </div>
</template>

<script>
/**
 * Inspiré de
 * @see https://github.com/OBrown92/vue2-leaflet-semicircle/blob/main/Vue2LeafletSemicircle.vue
 */

import {DomEvent} from "leaflet";
import {findRealParent, propsBinder} from "vue2-leaflet";
import "leaflet-ellipse";

const props = {
    latLng: {
        type: Array,
    },
    radius: {
        type: Array,
    },
    tilt: {
        type: Number,
    },
    visible: {
        type: Boolean,
        custom: true,
        default: true,
    },
};

export default {
    props: props,
    data() {
        return {
            ready: false,
        };
    },
    mounted() {
        this.mapObject = new L.Ellipse(this.latLng, this.radius,  this.tilt);
        DomEvent.on(this.mapObject, this.$listeners);
        propsBinder(this, this.mapObject, props);
        this.ready = true;
        this.parentContainer = findRealParent(this.$parent);
        this.parentContainer.addLayer(this, !this.visible);
    },
    beforeDestroy() {
        this.parentContainer.removeLayer(this);
    },
    methods: {
        setVisible(newVal, oldVal) {
            if (newVal === oldVal) return;
            if (this.mapObject) {
                if (newVal) {
                    this.mapObject.addTo(this.parent);
                } else {
                    this.parent.removeLayer(this.mapObject);
                }
            }
        },
    },
};
</script>