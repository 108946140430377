<template>
    <b-modal
        :id="modalId"
        hide-footer
        v-bind="$attrs"
        v-model="modalShow"
    >
        <div class="text-center">
            <b-overlay :show="loading" spinner-small rounded :opacity="0.6">
                <div v-html="content" :class="loading ? 'invisible' : null" style="min-height: 50px;"></div>
            </b-overlay>
        </div>
    </b-modal>
</template>

<script>
import * as Sentry from '@sentry/vue';

export default {
    data: function () {
        return {
            content: null,
            loading: false,
            modalShow: false,
        };
    },
    props: {
        modalId: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            required: true,
        },
    },
    watch: {
        url(newValue) {
            if (newValue) {
                this.content = null;
                this.load();
            }
        },
    },
    methods: {
        load() {
            this.$bvModal.show(this.modalId);
            this.loading = true;
            fetch(this.url)
                .then(response => response.text())
                .then(html => (this.content = html))
                .finally(() => (this.loading = false))
                .catch(err => {
                    Sentry.captureException(err);
                    this.$bvModal.hide(this.modalId);
                    document.dispatchEvent(new CustomEvent('app.flash', {detail: {type: 'error', flash: 'Impossible de charger le contenu.'}}));
                })
            ;
        },
    },
}
</script>