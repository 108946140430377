<template>
    <div class="card m-0 h-100" :class="{'card-appels': 'Carto' === module}">
        <div
            class="bloc-list-appel card-header d-flex justify-content-between align-items-center bg-danger text-white"
            :class="'Regulation' !== module ? 'p-2' : null"
        >
            <span class="text-title">Appels <span class="small">(<span v-show="appels.length !== filteredAppels.length">{{ filteredAppels.length }} / </span>{{ appels.length }})</span></span>
            <div class="d-flex my-n1">
                <treeselect
                    v-model="appelsZoneFilter"
                    class="text-dark"
                    :clear-on-select="true"
                    :clearable="true"
                    :multiple="true"
                    no-results-text="Aucun élément."
                    :normalizer="node => ({id: node.code, label: node.libelle})"
                    open-direction="below"
                    :options="zones"
                    placeholder="Toutes les zones"
                    value-format="object"
                    style="max-width: 240px"
                ></treeselect>
                <button v-if="'Regulation' === module" type="button" class="btn btn-xs btn-clear ml-2 d-none d-xl-block" @click="$emit('toggleexpanded', 'appels')">
                    <i class="fa-solid fa-lg" :class="expanded === 'appels' ? 'fa-compress-alt' : 'fa-expand-alt'" :title="expanded === 'appels' ? 'Réduire' : 'Agrandir'"></i>
                    <template v-if="expanded === 'appels'">
                        Afficher les missions
                    </template>
                </button>
                <b-button v-else-if="'Carto' === module" class="p-1 width-30px ml-2" @click="$emit('close')">
                    <i class="fa-solid fa-xmark"></i>
                </b-button>
            </div>
        </div>
        <div class="card-body p-0">
            <b-table
                style="min-height: 500px"
                class="mb-0"
                ref="appelsTable"
                hover
                show-empty
                :items="filteredAppels"
                :fields="appelsFields.filter(f => !columns || columns.includes(f.key))"
                :sort-by.sync="appelsSortBy"
                :sort-desc.sync="appelsSortDesc"
                :responsive="true"
                :tbody-tr-class="listTrClass()"
                small
                @row-clicked="$emit('selectappel', $event.numero)"
            >
                <template v-slot:table-busy>
                    <div class="text-center my-2">
                        <b-spinner></b-spinner>
                    </div>
                </template>
                <template v-slot:empty="scope">
                    <div class="text-muted text-center">Aucun élément.</div>
                </template>
                <template v-slot:cell(numero)="{item}">
                    <span :title="item.numero" class="text-number">{{ item.numero|substring(5, 9) }}</span><span v-if="item.demande && item.demande.entraineRegulationSecondaire" title="Secondaire" class="font-weight-bold">S</span>
                </template>
                <template v-slot:cell(regulateur)="{item}">
                    <span class="badge badge-gray" v-if="item.regulateur" title="Régulé">R</span>
                </template>
                <template v-slot:cell(gravite)="{item}">
                    <span :class="'badge gravite-' + item.gravite.code" v-if="item.gravite" title="Gravité">{{ item.gravite.libelle }}</span>
                </template>
                <template v-slot:cell(affaire._estVerrouille)="{item}">
                    <span v-if="item.affaire._estVerrouille" :title="'Modification en cours par ' + (item.affaire._estVerrouille.identite ? item.affaire._estVerrouille.identite : 'un autre utilisateur') + (item.affaire._estVerrouille.poste ? ' (' + item.affaire._estVerrouille.poste + ')' : '')"><i class="fa-solid fa-lock text-warning"></i></span>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import {
    APPEL_DEMANDE,
    APPEL_DEMANDE_ATTR,
    APPEL_ZONE,
    APPEL_ZONE_ATTR,
    DEFAULT_ATTR,
    HORODATAGE_ATTR,
    HORODATAGE_FORMATTER,
    HORODATAGE_SORT_FORMATTER
} from "../../../app/BootstrapVueHelpers";

export default {
    data: function () {
        return {
            appelsZoneFilter: [],
            appelsSortBy: 'horodatage',
            appelsSortDesc: false,
        };
    },
    props: {
        appels: {
            type: Array,
            required: true,
        },
        canChangeAppel: {
            type: Boolean,
            required: false,
            default: true,
        },
        columns: {
            type: Array,
            required: false,
            default: null,
        },
        currentAppelNumero: {
            type: String,
            required: false,
            default: null,
        },
        expanded: {
            type: String,
            required: false,
            default: null,
        },
        module: {
            type: String,
            required: true,
        },
        zones: {
            type: Array,
            required: true,
        },
        zonesParDefaut: {
            type: Array,
            required: true,
        },
    },
    computed: {
        filteredAppels() {
            return this.appels.filter((appel) => {
                return 0 === this.appelsZoneFilter.length || appel.zone && this.appelsZoneFilter.some(zone => zone.code === appel.zone.code);
            });
        },
    },
    methods: {
        listTrClass() {
            return (item) => {
                let classes = [];

                if (item) {
                    if (this.canChangeAppel) {
                        classes.push('cursor-pointer');
                    }
                    if (this.currentAppelNumero && item && this.currentAppelNumero === item.numero) {
                        classes.push('bg-secondary');
                    }
                }

                return classes;
            };
        },
    },
    created() {
        this.appelsFields = [
            { key: 'numero', label: '#', headerTitle: 'Numéro', tdClass: 'width-1px', sortable: true },
            { key: 'horodatage', label: 'HAp', headerTitle: 'Heure d\'appel', tdClass: 'text-number width-1px', sortable: true, sortByFormatted: HORODATAGE_SORT_FORMATTER, formatter: HORODATAGE_FORMATTER, tdAttr: HORODATAGE_ATTR },
            { key: 'regulateur', label: '', tdClass: 'width-1px', sortable: true },
            { key: 'gravite', label: '', tdClass: 'width-1px', sortable: true },
            { key: '_metaLieu', label: 'Lieu', tdClass: 'text-ellipsis', thStyle: { 'min-width': '100px' }, sortable: true, tdAttr: DEFAULT_ATTR },
            { key: '_metaNom', label: 'Nom', tdClass: 'text-ellipsis', thStyle: { 'min-width': '100px' }, sortable: true, tdAttr: DEFAULT_ATTR },
            { key: 'demande.libelle', label: 'Demande', tdClass: 'text-ellipsis', sortable: true, thStyle: { 'min-width': '100px' }, formatter: APPEL_DEMANDE, tdAttr: APPEL_DEMANDE_ATTR },
            { key: 'zone.libelle', label: 'Zone', tdClass: 'text-nowrap width-1px', sortable: true, formatter: APPEL_ZONE, tdAttr: APPEL_ZONE_ATTR },
            { key: '_metaObservation', label: 'Observation', tdClass: 'text-ellipsis', thStyle: { 'min-width': '100px' }, sortable: true, tdAttr: DEFAULT_ATTR },
            { key: 'affaire._estVerrouille', label: '', tdClass: 'width-1px' },
        ];
    },
    beforeMount() {
        for (let code of this.zonesParDefaut) {
            let zone = this.zones.find(z => z.code === code);
            zone && this.appelsZoneFilter.push(zone);
        }
    },
}
</script>