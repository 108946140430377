import Vue from "vue";
import Router from "../../utils/Router";
import Request from "../../utils/Request";
import moment from "moment/moment";

export default {
    data: function () {
        return {
            devenir: null,
            devenirChoices: null,
            devenirErrors: {},
            devenirSaving: false,
            devenirSkeleton: null,
            refus: null,
            refusChoices: null,
            refusErrors: {},
            refusSaving: false,
            refusSkeleton: null,
        };
    },
    methods: {
        refreshDevenirs(appel) {
            for (let i = 0; i < this.currentAppel.patientCollection.length; i++) {
                for (let j = 0; j < appel.patientCollection.length; j++) {
                    if (this.currentAppel.patientCollection[i].numeroPatient === appel.patientCollection[j].numeroPatient) {
                        Vue.set(this.currentAppel.patientCollection[i], 'devenirCollection', appel.patientCollection[j].devenirCollection);
                        Vue.set(this.currentAppel.patientCollection[i], 'refusCollection', appel.patientCollection[j].refusCollection);
                        if (this.currentAppel._patient && this.currentAppel._patient.numeroPatient === appel.patientCollection[j].numeroPatient) {
                            Vue.set(this.currentAppel._patient, 'devenirCollection', appel.patientCollection[j].devenirCollection);
                            Vue.set(this.currentAppel._patient, 'refusCollection', appel.patientCollection[j].refusCollection);
                        }
                    }
                }
            }
            this.carto && this.updateMarkers();
        },
        addDevenir(patient, numeroDevenir = null) {
            this.devenir = JSON.parse(JSON.stringify(this.devenirSkeleton));
            if (numeroDevenir) {
                const existing = patient.devenirCollection.find(d => d.numeroDevenir === numeroDevenir);
                for (let key in existing) {
                    if (typeof this.devenir[key] !== 'undefined') {
                        this.devenir[key] = existing[key];
                    }
                }
            } else if (this.carto && this.carto.marker && this.carto.marker.id.match(/^ETB/)) {
                Vue.set(this.devenir, 'precomplete', true);
                Vue.set(this.devenir, 'codeEtablissement', this.carto.marker.code);
                Vue.set(this.devenir, 'etablissement', this.carto.marker.libelle);
                Vue.set(this.devenir, 'adresse1', this.carto.marker.adresse[0] || null);
                Vue.set(this.devenir, 'ville', (this.carto.marker.adresse[2] || '').replace(/^\d+\s/, ''));
                Vue.set(this.devenir, 'codePostal', (this.carto.marker.adresse[2] || '').replace(/\D+/g, ''));
                Vue.set(this.devenir, 'telephone1', this.carto.marker.telephone.filter(t => t.type === 'tel').map(t => t.numero)[0] || null);
                if (this.detailSelected && this.detailSelected.codeEtablissement === this.carto.marker.code) {
                    Vue.set(this.devenir, 'service', this.detailSelected.libelle);
                    Vue.set(this.devenir, 'telephone2', this.detailSelected.telephone);
                    Vue.set(this.devenir, 'contact', this.detailSelected.contact);
                }
            }
            this.devenir.numeroAppel = this.currentAppel.numero;
            this.devenir.numeroPatient = patient.numeroPatient;
            this.devenirSaving = false;
            this.devenirErrors = {};

            this.modal = 'devenir';
        },
        saveDevenir() {
            this.devenirSaving = true;

            let route;
            if (this.devenir.numeroDevenir) {
                route = Router.generate('appel.devenir.edit', {
                    appel: this.currentAppel.numero,
                    patient: this.devenir.numeroPatient,
                    devenir: this.devenir.numeroDevenir
                });
            } else {
                route = Router.generate('appel.devenir.new', {
                    appel: this.currentAppel.numero,
                    patient: this.devenir.numeroPatient
                });
            }
            Request.fetchJson(
                route,
                'POST',
                this.normalize(JSON.parse(JSON.stringify(this.devenirSkeleton)), this.devenir)
            ).then((response) => {
                if (response.success) {
                    this.devenirErrors = {};
                    this.modal = null;
                    this.refreshDevenirs(response.appel);
                } else {
                    this.devenirErrors = response.errors;
                }
            }, () => this.devenirErrors = {'': ['Une erreur est survenue, les données n\'ont pas pu être enregistrées.']})
                .finally(() => this.devenirSaving = false);
        },
        addRefus(patient, numeroRefus = null) {
            this.refus = JSON.parse(JSON.stringify(this.refusSkeleton));
            const now = moment();
            this.refus.date = {
                date: now.format('DD/MM/YYYY'),
                time: now.format('HH:mm'),
            };

            if (numeroRefus) {
                const existing = patient.refusCollection.find(d => d.numeroRefus === numeroRefus);
                for (let key in existing) {
                    if (typeof this.refus[key] !== 'undefined') {
                        this.refus[key] = existing[key];
                    }
                }
            } else if (this.carto && this.carto.marker && this.carto.marker.id.match(/^ETB/)) {
                Vue.set(this.refus, 'precomplete', true);
                Vue.set(this.refus, 'codeEtablissement', this.carto.marker.code);
                Vue.set(this.refus, 'etablissement', this.carto.marker.libelle);
                Vue.set(this.refus, 'telephoneEtablissement', this.carto.marker.telephone.filter(t => t.type === 'tel').map(t => t.numero)[0] || null);
                if (this.detailSelected && this.detailSelected.codeEtablissement === this.carto.marker.code) {
                    Vue.set(this.refus, 'codeService', this.detailSelected.numero);
                    Vue.set(this.refus, 'service', this.detailSelected.libelle);
                    Vue.set(this.refus, 'telephoneService', this.detailSelected.telephone);
                }
            }
            this.refus.numeroAppel = this.currentAppel.numero;
            this.refus.numeroPatient = patient.numeroPatient;
            this.refusSaving = false;
            this.refusErrors = {};

            this.modal = 'refus';
        },
        saveRefus() {
            this.refusSaving = true;

            let route;
            if (this.refus.numeroRefus) {
                route = Router.generate('appel.refus.edit', {
                    appel: this.currentAppel.numero,
                    patient: this.refus.numeroPatient,
                    refus: this.refus.numeroRefus
                });
            } else {
                route = Router.generate('appel.refus.add', {
                    appel: this.currentAppel.numero,
                    patient: this.refus.numeroPatient
                });
            }
            Request.fetchJson(
                route,
                'POST',
                this.normalize(JSON.parse(JSON.stringify(this.refusSkeleton)), this.refus)
            ).then((response) => {
                if (response.success) {
                    this.refusErrors = {};
                    this.modal = null;
                    this.refreshDevenirs(response.appel);
                } else {
                    this.refusErrors = response.errors;
                }
            }, () => this.refusErrors = {'': ['Une erreur est survenue, les données n\'ont pas pu être enregistrées.']})
                .finally(() => this.refusSaving = false);
        },
        onDevenirTypeaheadSelect(field, data) {
            if ('etablissement' === field) {
                Vue.set(this.devenir, 'codeEtablissement', data.code);
                Vue.set(this.devenir, 'etablissement', data.libelle);
                Vue.set(this.devenir, 'adresse1', data.adresse1);
                Vue.set(this.devenir, 'ville', data.ville);
                Vue.set(this.devenir, 'codePostal', data.codePostal);
                Vue.set(this.devenir, 'telephone1', data.telephone);
            } else if ('service' === field) {
                Vue.set(this.devenir, 'service', data.libelle);
                Vue.set(this.devenir, 'telephone2', data.telephone);
                Vue.set(this.devenir, 'contact', data.contact);
            } else if ('adresse1' === field) {
                Vue.set(this.devenir, 'adresse1', data.libelleComplet || data.libelle);
                Vue.set(this.devenir, 'ville', data.ville);
                Vue.set(this.devenir, 'codePostal', data.codePostal);
            } else if ('ville' === field || 'codePostal' === field) {
                Vue.set(this.devenir, 'ville', data.libelle);
                Vue.set(this.devenir, 'codePostal', data.codePostal);
            }
        },
        onDevenirTypeaheadInput(field) {
            if ('etablissement' === field) {
                Vue.set(this.devenir, 'codeEtablissement', null);
            }
            if ('ville' === field || 'etablissement' === field) {
                Vue.set(this.devenir, 'codePostal', null);
            }
        },
        onRefusTypeaheadSelect(field, data) {
            if ('etablissement' === field) {
                Vue.set(this.refus, 'codeEtablissement', data.code);
                Vue.set(this.refus, 'etablissement', data.libelle);
                Vue.set(this.refus, 'telephoneEtablissement', data.telephone);
            } else if ('service' === field) {
                Vue.set(this.refus, 'codeService', data.numero);
                Vue.set(this.refus, 'service', data.libelle);
                Vue.set(this.refus, 'telephoneService', data.telephone);
            } else if ('adresse1' === field) {
                Vue.set(this.devenir, 'adresse1', data.libelleComplet || data.libelle);
            } else if ('ville' === field || 'codePostal' === field) {
                Vue.set(this.devenir, 'ville', data.libelle);
                Vue.set(this.devenir, 'codePostal', data.codePostal);
            }
        },
        onRefusTypeaheadInput(field) {
            if ('etablissement' === field) {
                Vue.set(this.refus, 'codeEtablissement', null);
                Vue.set(this.refus, 'telephoneEtablissement', null);
            }
            if ('service' === field) {
                Vue.set(this.refus, 'codeService', null);
                Vue.set(this.refus, 'telephoneService', null);
            }
        },
        onDevenirMissionSelect(type, data) {
            if ('transportePar' === type) {
                this.devenir.moyen = data ? data.moyen : null;
                this.devenir.organisme = data ? data.organisme : null;
                this.devenir.moyenAutre = data ? data.moyenAutre : null;
            } else {
                this.devenir.moyenMedicalise = data ? data.moyen : null;
                this.devenir.organismeMedicalise = data ? data.organisme : null;
                this.devenir.moyenMedicaliseAutre = data ? data.moyenAutre : null;
            }
        },
    },
};