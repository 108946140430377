<template>
    <li
        class="mr-2 p-1 my-1 d-flex align-items-center bg-white"
        :class="{'cursor-pointer': !!status}"
        id="cti-statut"
        style="border-radius: 3px"
        @click="changeStatus()"
        @contextmenu.prevent="$refs.ctxMenuCti.open"
    >
        <context-menu ref="ctxMenuCti">
            <li v-for="group in groups" class="pl-2" @click="changeGroup(group)">
                <i v-if="group.code === selectedGroup" class="fa-solid fa-fw fa-circle-dot text-info"></i>
                <i v-else class="fa-regular fa-fw fa-circle"></i>
                {{ group.libelle }}
            </li>
        </context-menu>
        <b-popover v-if="!$refs.ctxMenuCti || !$refs.ctxMenuCti.ctxVisible" target="cti-statut" triggers="hover" placement="left">
            <template #title>{{ statusTitle }}</template>
            <div v-if="null !== status">Clic gauche pour changer de statut.</div>
            <div v-if="null !== status">Clic droit pout changer de groupe.</div>
        </b-popover>
        <i
            class="fa-solid fa-2x fa-fw"
            :class="{
                'fa-phone-hangup text-success': 'READY' === status,
                'fa-pause text-warning fa-beat': 'PAUSE' === status,
                'fa-phone text-danger': 'BUSY' === status,
                'fa-phone-slash text-muted': !status,
            }"
        ></i>
        <div class="ml-2 small" :class="{'text-muted': !status}">
            <div>{{ ctiData.device }}</div>
            <div>{{ groups.length && selectedGroup ? groups.find(g => g.code === selectedGroup).libelle : ctiData.group.libelle }}</div>
        </div>
    </li>
</template>

<script>
export default {
    data: function () {
        return {
            status: null,
            groups: [],
            selectedGroup: null,
        };
    },
    props: {
        ctiData: {
            type: Object,
            required: true,
        },
    },
    beforeMount() {
        this.selectedGroup = this.ctiData.group.code;

        webSocket.subscribe('cti_statut/' + this.ctiData.agent.code, (url, data) => {
            if (data) {
                if (data.type === 'status') {
                    this.status = data.data;
                } else if (data.type === 'logout') {
                    this.status = null;
                } else if (data.type === 'groups') {
                    this.groups = data.data;
                } else if (data.type === 'update') {
                    this.selectedGroup = data.group_id;
                } else if (data.type === 'failure') {
                    document.dispatchEvent(new CustomEvent('app.flash', {detail: {type: 'error', flash: data.flash}}));
                }
            }
        });
        document.addEventListener('websocket', (e) => {
            if (!e.detail.online) {
                this.status = null;
            }
        });
        window.addEventListener('appelLocked', (e) => {
            if (e.detail.state && this.status === 'READY') {
                webSocket.publish('cti_statut/' + this.ctiData.agent.code, {type: 'status', data: 'BUSY'});
            } else if (!e.detail.state && this.status === 'BUSY') {
                webSocket.publish('cti_statut/' + this.ctiData.agent.code, {type: 'status', data: 'READY'});
            }
        });
        window.addEventListener('beforeunload', this.onUnload);
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.onUnload);
    },
    computed: {
        statusTitle() {
            if (this.status === 'BUSY') {
                return 'Statut : occupé'
            }
            if (this.status === 'READY') {
                return 'Statut : disponible'
            }
            if (this.status === 'PAUSE') {
                return 'Statut : en pause'
            }

            return 'Statut : inconnu'
        }
    },
    methods: {
        changeStatus() {
            if (!this.status) {
                return;
            }
            const newStatus = this.status === 'PAUSE' || this.status === 'BUSY' ? 'READY' : 'PAUSE';

            webSocket.publish('cti_statut/' + this.ctiData.agent.code, {type: 'status', data: newStatus});
        },
        changeGroup(group) {
            webSocket.publish('cti_statut/' + this.ctiData.agent.code, {
                type: 'update',
                group_id: group.code,
                device: this.ctiData.device,
            });
        },
        onUnload() {
            if (this.status) {
                webSocket.publish('cti_statut/' + this.ctiData.agent.code, {type: 'unload'});
            }
        },
    },
}
</script>
