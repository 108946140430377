/*
 * Utilisation :
 * - Importer la mixin dans l'instance Vue
 * - {% include 'app/Utils/modal-shortcuts-help.html.twig' %}
 * - Définir `shortcuts` dans le `data` de l'instance Vue (se cumule avec ceux de la mixin)
 * - Définir les méthodes correspondantes
 * - Profit
 */
export default {
    data: {
        shortcuts: {
            // @see https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
            // /!\ Lowercase keys only
            'h': {
                description: 'Afficher les raccourcis clavier (<b style="text-decoration: underline">H</b>elp).',
                handler: 'onShortcutHelp',
            },
        },
        showShortcutsHelp: false,
    },
    mounted() {
        const btn = document.getElementById('button-shortcuts-help');
        if (btn) {
            btn.classList.remove('d-none');
            btn.addEventListener('click', () => this.showShortcutsHelp = true);
        }

        document.addEventListener('keydown', event => {
            if (
                !['Alt', 'Control', 'Shift'].includes(event.key)
                && (
                    event.ctrlKey
                    || !['INPUT', 'TEXTAREA', 'OPTION', 'SELECT', 'BUTTON'].includes(event.target.tagName.toUpperCase())
                )
            ) {
                const key = [
                    event.altKey ? 'alt' : null,
                    event.ctrlKey ? 'control' : null,
                    event.shiftKey ? 'shift' : null,
                    event.key,
                ].filter(v => v || ' ' === v).join('-').toLowerCase();
                if (this.shortcuts[key] && this[this.shortcuts[key].handler]) {
                    event.preventDefault();
                    this[this.shortcuts[key].handler](event);
                }
            }
        });
    },
    methods: {
        onShortcutHelp() {
            this.showShortcutsHelp = !this.showShortcutsHelp;
        },
    },
}