<template>
    <span v-bind="$attrs" @click="openModal()">
        <slot></slot>
        <b-modal
            v-model="showModal"
            centered
            header-bg-variant="info"
            header-text-variant="white"
            hide-footer
            hide-header
            id="modal-repertoire"
            body-class="p-0"
            size="xl"
            title="Répertoire"
        >
            <div class="card shadow-lg mb-0">
                <div class="card-header d-flex text-white justify-content-between align-missions-center" style="background: rgb(125, 125, 125);">
                    <h6 class="card-title m-0">Répertoire</h6>
                    <div class="d-flex my-n1 mr-n1">
                        <button type="button" title="Fermer" class="btn btn-xs btn-clear" @click="showModal = false">
                            <i class="fa-solid fa-lg fa-times"></i>
                        </button>
                    </div>
                </div>

                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-4">
                            <b-input v-model="filterLibelle" placeholder="Libellé"></b-input>
                        </div>
                        <div class="col-4">
                            <b-input v-model="filterAdresse" placeholder="Adresse"></b-input>
                        </div>
                        <div class="col-3">
                            <form-select
                                v-model="form.type"
                                large
                                :options="types"
                                placeholder="Tous les types"
                                :reduce="item => item.code"
                                @input="refreshSearch()"
                            ></form-select>
                        </div>
                        <div class="col-1">
                            <b-button type="button" block @click="clear()" title="Effacer les filtres">
                                <i class="fa-solid fa-refresh"></i>
                            </b-button>
                        </div>
                    </div>

                    <b-table
                        :busy="loading"
                        class="mb-0"
                        :current-page="currentPage"
                        hover
                        :items="items"
                        :fields="fields"
                        no-sort-reset
                        primary-key="code"
                        :responsive="true"
                        show-empty
                        small
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        @sort-changed="refreshSearch"
                    >
                        <template v-slot:table-busy>
                            <div class="text-center mt-4 mb-3">
                                <b-spinner></b-spinner>
                            </div>
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-muted text-center">Aucun élément.</div>
                        </template>

                        <template v-slot:cell(libelle)="{item}">
                            {{ item.libelle }}

                            <div v-if="item.type">
                                <span class="text-muted">{{ item.type.libelle }}</span>
                            </div>
                        </template>
                        <template v-slot:cell(type.libelle)="{item}">
                            {{ item.type ? item.type.libelle : '' }}
                        </template>
                        <template v-slot:cell(adresse)="{item}">
                            {{ item.noVoie }} {{ item.voie }}

                            <div v-if="item.codePostal || item.ville">
                                <span class="text-muted mr-1">{{ item.codePostal }}</span>{{ item.ville }}
                            </div>
                        </template>
                        <template v-slot:cell(telephoneCollection)="{item}">
                            <b-input-group
                                v-for="(telephone, index) in item.telephoneCollection"
                                class="mb-1"
                                :key="index"
                                size="sm"
                            >
                                <b-input-group-prepend>
                                    <b-input-group-text class="d-inline-block text-right" style="width: 300px" :title="telephone.libelle">{{ telephone.libelle || '&nbsp;' }}</b-input-group-text>
                                </b-input-group-prepend>
                                <b-input-group-prepend>
                                    <b-input-group-text class="d-inline-block bg-white" style="width: 150px" :title="telephone.telephone">{{ telephone.telephone }}</b-input-group-text>
                                </b-input-group-prepend>
                                <button-telephone :telephone="telephone.telephone" :border-radius-right="!smsEnabled"></button-telephone>
                                <button
                                    v-if="smsEnabled"
                                    class="btn btn-sm btn-outline-info brl-0 border-left-0"
                                    title="Transmettre les coordonnées par SMS"
                                    type="button"
                                    v-b-modal="'modal-form-sms-' + item.code + '-' + telephone.code"
                                    v-b-tooltip.hover.left
                                >
                                    <i class="fa-fw fa-solid fa-message-text"></i>
                                    <form-sms
                                        :appel="numeroAppel"
                                        :csrf="csrf.sms"
                                        :message-initial="computeSms(item, telephone)"
                                        :modal-id="'modal-form-sms-' + item.code + '-' + telephone.code"
                                        :telephone-initial="smsTelephone"
                                    >
                                    </form-sms>
                                </button>
                            </b-input-group>
                            <div class="text-center text-muted" v-if="!item.telephoneCollection.length" title="Aucun téléphone renseigné.">-</div>
                        </template>
                    </b-table>

                    <div class="d-flex align-items-center justify-content-between" v-if="totalItemCount" @click.stop>
                        <div></div>
                        <b-overlay :show="loading" opacity="0.6">
                            <template #overlay><div></div></template>
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalItemCount"
                                :per-page="itemNumberPerPage"
                                class="m-0"
                            ></b-pagination>
                        </b-overlay>
                        <div v-if="loading"></div>
                        <div class="mr-3 mt-1" v-else>Éléments {{ ((currentPage-1) * itemNumberPerPage) + 1 }} à {{ (currentPage - 1) * itemNumberPerPage + items.length }} sur <b>{{ totalItemCount }}</b></div>
                    </div>
                </div>
            </div>
        </b-modal>
    </span>
</template>

<script>
import Request from "../../../utils/Request";
import Router from "../../../utils/Router";

export default {
    data: function () {
        return {
            csrf: {sms: null},
            currentPage: 1,
            fields: [
                {key: 'libelle', label: 'Libellé', sortable: true},
                {key: 'adresse', label: 'Adresse', sortable: false},
                {key: 'telephoneCollection', label: 'Téléphones', sortable: false, thStyle: {width: '50%'}},
            ],
            filterLibelle: null,
            filterAdresse: null,
            form: {
                libelle: null,
                adresse: null,
                type: null,
            },
            initialized: false,
            items: null,
            loading: true,
            itemNumberPerPage: null,
            showModal: false,
            smsEnabled: false,
            sortBy: 'libelle',
            sortDesc: false,
            totalItemCount: null,
            types: [],
        };
    },
    props: {
        numeroAppel: {
            type: String,
            required: false,
            default: null,
        },
        smsTelephone: {
            type: String,
            required: false,
            default: null,
        },
    },
    methods: {
        clear() {
            this.form.type = null;
            this.currentPage = 1;
            this.filterLibelle = null;
            this.filterAdresse = null;
        },
        openModal() {
            this.showModal = true;
            if (null === this.items) {
                this.refreshSearch();
            }
        },
        refreshSearch() {
            this.loading = true;
            let params = {
                page: this.currentPage,
                repertoire_filter: this.form,
                direction: this.sortDesc ? 'desc' : 'asc',
                ordre: ('a.' + this.sortBy).split('.').slice(-2).join('.'),
            };

            Request.fetchJson(Router.generate('repertoire.search', params))
                .then(data => {
                    this.csrf = data.csrf;
                    this.types = data.types;
                    this.items = data.items;
                    this.smsEnabled = data.smsEnabled;
                    this.currentPage = data.currentPage;
                    this.totalItemCount = data.totalItemCount;
                    this.itemNumberPerPage = data.itemNumberPerPage;
                })
                .finally(() => {
                    this.loading = false;
                })
            ;
        },
        computeSms(contact, telephone) {
            return [
                'Le SAMU vous oriente vers cette destination :',
                contact.libelle,
                ((contact.noVoie || '') + ' ' + (contact.voie || '')).trim(),
                ((contact.codePostal || '') + ' ' + (contact.ville || '')).trim(),
                telephone.telephone ? ((telephone.libelle || 'Tél.') + ' : ' + telephone.telephone).trim() : '',
            ].filter(v => !!v).join("\n");
        },
    },
    watch: {
        currentPage() {
            this.refreshSearch();
        },
        filterLibelle: Request.debounce(function(newValue) {
            this.form.libelle = newValue;
            this.refreshSearch();
        }, 300),
        filterAdresse: Request.debounce(function(newValue) {
            this.form.adresse = newValue;
            this.refreshSearch();
        }, 300),
    },
}
</script>