<template>
    <v-select v-bind="$attrs" :class="classes" :style="style" :disabled="disabled" :label="label || 'libelle'" :options="options" :clearable="clearable" :value="value" @input="$emit('input', $event)" @click.stop>
        <template #open-indicator="{ attributes }">
            <i v-bind="attributes" class="fa-solid fa-chevron-down text-dark"></i>
        </template>
        <template #no-options>
            <div class="text-left pl-3">
                Aucun élément.
            </div>
        </template>
        <template #option="{ id, libelle }">
            <i v-if="isSecteurType && getSecteurTypeDefault('icone', id)" :class="getSecteurTypeDefault('icone', id) + ' ' + getSecteurTypeDefault('color', id)" class="fa-fw mr-1"></i>
            {{ libelle }}
        </template>
    </v-select>
</template>

<script>
    export default {
        props: ['value', 'width', 'options', 'label', 'small', 'large', 'clear', 'clearable', 'disabled', 'secteurDefaults'],
        computed: {
            style() {
                if(this.width) {
                    return {width: this.width};
                }
            },
            classes() {
                return {'vue-select-small': this.small !== undefined, 'vue-select-large': this.large !== undefined, 'vue-select-clear': this.clear !== undefined};
            },
            isSecteurType() {
                return this.secteurDefaults;
            }
        },
        mounted() {
            this.$el.querySelector('.vs__clear').setAttribute('title', 'Vider la sélection');
        },
        methods: {
            getSecteurTypeDefault(data, id) {
                return this.secteurDefaults[data][id] ?? '';
            },
        }
    }
</script>