import Vue from 'vue';
import Router from '../utils/Router';
import Request from "../utils/Request";
import moment from 'moment';

import feries_24_25_26 from '../../json/feries_24_25_26.json';

Vue.config.productionTip = false;

export default class {
    constructor() {
        new Vue({
            el: '#app',
            delimiters: ['[[', ']]'],
            data: {
                categorieSelected: null,
                couvertureSelected: null,
                couvertureDate: null,
                clipboard: [],
                gardeSelected: null,
                grille: null,
                recap: null,
                grilleEquipe: null,
                loadingCouverture: false,
                loadingEquipe: false,
                loadingGrille: false,
                modalDeleteShow : false,
                newEquipe: '',
                ambulanceSearch: null,
                ambulanceShowAll: false,
                medecinSearch: null,
                medecinShowAll: false,
                personnelQualification: '',
                personnelSearch: null,
                personnelShowAll: null,
                pharmacieSearch: null,
                pharmacieShowAll: null,
                semaine: {
                    moment: null,
                    numero: null,
                    annee: null,
                    debut: null,
                    fin: null,
                },
                semaines: null,
                semaineJours: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'],
                semaineForms: {},
                showMap: false,
                showCouverture: false,
                bufferCouverture: {},
                feries: {},
            },
            beforeMount() {
                const data = JSON.parse(this.$el.dataset.data);

                for (let key in data) {
                    this[key] = data[key];
                }
            },
            mounted() {
                this.$el.classList.remove('invisible');

                this.personnels.forEach(p => {
                    p.organismeCollection = [...new Set(JSON.parse(p.organismeCollection))];
                    if(p.qualificationCollection) {
                        p.qualificationCollection = [...new Set(JSON.parse(p.qualificationCollection))];
                        p.qualifications = [...new Set(JSON.parse(p.qualifications))];
                    }
                });

                this.semaineJours.forEach(j => {
                    Vue.set(this.semaineForms, j, {
                        debut: '',
                        fin: '',
                        error: false,
                    });
                });

                this.semaine.moment = moment();
                this.updateWeek();
                this.updateRecap();

                feries_24_25_26.forEach(f => {
                    this.feries[f.startDate] = f.name[0].text;
                });

                if(this.preselection) {
                    const preselection = this.gardes.filter(g => g.id === this.preselection);
                    if (preselection.length) {
                        this.selectGarde(preselection[0]);
                    }

                    App.Layout.pushState({}, (window.location + '').replace('?preselection=' + this.preselection, ''));

                }
            },
            watch: {
            },
            methods: {
                reset() {
                    this.clipboard = [];
                    this.newEquipe = '';

                    this.ambulanceSearch = null;
                    this.ambulanceShowAll = false;

                    this.medecinSearch = null;
                    this.medecinShowAll = false;

                    this.personnelQualification = '';
                    this.personnelSearch = null;

                    this.pharmacieSearch = null;
                    this.pharmacieShowAll = null;
                },
                changeCategorie(categorie) {
                    this.gardeSelected = null;
                    this.grille = null;
                    this.grilleEquipe = null;

                    this.showMap = false;
                    this.showCouverture = false;

                    this.categorieSelected = categorie;

                    this.reset();
                },
                selectGarde(garde, hideMap) {
                    this.gardeSelected = garde;

                    if (hideMap) {
                        this.showMap = false;
                        this.showCouverture = false;
                    }

                    this.reset();
                    this.updateGrille();

                    setTimeout(() => {
                        document.getElementById('garde_list').scrollTop = document.getElementById('garde_' + garde.code).offsetTop - 5;
                    }, 100);

                    this.$refs.carto.hideAllCommunes();
                    this.gardeSelected.communeCollection.forEach(insee => {
                        this.$refs.carto.setCommunesState(insee, 'selected_noborder');
                    });
                    this.$refs.carto.applyCommunesState();
                },
                noGarde(plage) {
                    this.addRessources([{code: '-1',libelle: 'Pas de garde'}], plage);
                },
                updatePlageNoGarde(plage) {
                    plage.noGarde = plage.ressources.length === 1 && parseInt(plage.ressources[0].code) === -1;
                },
                isCouvertureNoGarde(buffer) {
                    return buffer.length === buffer.filter(r => parseInt(r.code) === -1).length;
                },
                addRessources(ressources, plage) {
                    if(plage) {
                        plage.saving = true;

                        Request.fetchJson(Router.generate('garde.add'), 'POST', {
                            plage: plage,
                            ressources: ressources,
                        }).then(response => {
                            if (response.success && response.ressources) {
                                plage.ressources = plage.ressources.concat(response.ressources);
                            }

                            this.updatePlageNoGarde(plage);
                            this.updateRecap();
                            plage.saving = false;
                        }, () => {
                            plage.saving = false;
                        });
                    }
                },
                addRessourcesEquipe(ressources, codeAmu, equipe) {
                    if(equipe) {
                        this.loadingEquipe = true;

                        Request.fetchJson(Router.generate('garde.add.equipier'), 'POST', {
                            codeAmu: codeAmu,
                            equipe: equipe,
                            ressources: ressources,
                        }).then(response => {
                            if (response.success && response.personnels) {
                                if(!this.equipiers[codeAmu]) {
                                    this.equipiers[codeAmu] = [];
                                }
                                if(!this.equipiers[codeAmu][equipe]) {
                                    this.equipiers[codeAmu][equipe] = [];
                                }

                                this.equipiers[codeAmu][equipe] = this.equipiers[codeAmu][equipe].concat(response.personnels);
                            }

                            this.loadingEquipe = false;
                        }, () => {
                            this.loadingEquipe = false;
                        });
                    }
                },
                addLibre(plage) {
                    if(plage) {
                        plage.ressources.push({
                            code: null,
                            libelle: '',
                            numero: null,
                            new: true,
                        });
                    }
                },
                deleteRessource(ressource, plage) {
                    if(plage && ressource.numero) {
                        plage.saving = true;

                        Request.fetchJson(Router.generate('garde.delete'), 'POST', {
                            plage: plage,
                            ressource: ressource,
                        }).then(response => {
                            if (response.success) {
                                plage.ressources.splice(plage.ressources.indexOf(ressource), 1);
                            }

                            this.updatePlageNoGarde(plage);
                            this.updateRecap();
                            plage.saving = false;
                        }, () => {
                            garde.saving = false;
                        });
                    } else {
                        plage.ressources.splice(plage.ressources.indexOf(ressource), 1);
                    }
                },
                deleteRessourceEquipe(ressource, codeAmu, equipe) {
                    if(codeAmu && equipe && ressource.code) {
                        this.loadingEquipe = true;

                        Request.fetchJson(Router.generate('garde.delete.equipier'), 'POST', {
                            codeAmu: codeAmu,
                            equipe: equipe,
                            codePersonnel: ressource['code'],
                        }).then(response => {
                            if (response.success) {
                                this.equipiers[codeAmu][equipe].splice(this.equipiers[codeAmu][equipe].indexOf(ressource), 1);
                            }

                            this.loadingEquipe = false;
                        }, () => {
                            this.loadingEquipe = false;
                        });
                    }
                },
                saveLibre(libre, plage) {
                    plage.saving = true;

                    Request.fetchJson(Router.generate(libre.new ? 'garde.add' : 'garde.update'), 'POST', {
                        plage: plage,
                        ressources: [libre],
                    }).then(response => {
                        if (response.success && response.ressources && response.ressources[0]) {
                            libre.new = false;
                            libre.numero = response.ressources[0].numero;
                        }

                        this.updatePlageNoGarde(plage);
                        this.updateRecap();
                        plage.saving = false;
                    }, () => {
                        plage.saving = false;
                    });
                },
                createEquipe(codeAmu) {
                    if(codeAmu && this.newEquipe) {
                        this.loadingEquipe = true;

                        Request.fetchJson(Router.generate('garde.create.equipe'), 'POST', {
                            codeAmu: codeAmu,
                            equipe: this.newEquipe,
                        }).then(response => {
                            if (response.success) {
                                this.gardeSelected.organisme.equipeCollection.push(this.newEquipe);
                            }

                            this.newEquipe = '';
                            this.loadingEquipe = false;
                        }, () => {
                            this.newEquipe = '';
                            this.loadingEquipe = false;
                        });
                    }
                },
                copier(plage) {
                    if(plage.ressources.length) {
                        this.clipboard = JSON.parse(JSON.stringify(plage.ressources.filter(r => !r.new)));
                    }
                },
                copierEquipe(equipe) {
                    if(equipe.length) {
                        this.clipboard = JSON.parse(JSON.stringify(equipe));
                    }
                },
                selectRessource(ressource){
                    if(ressource) {
                        this.clipboard = [JSON.parse(JSON.stringify(ressource))];
                    }
                },
                coller(plage) {
                    if(this.clipboard.length) {
                        this.addRessources(this.clipboard, plage);
                    }
                },
                collerEquipe(codeAmu, equipe) {
                    if(this.clipboard.length) {
                        this.addRessourcesEquipe(this.clipboard, codeAmu, equipe);
                    }
                },
                prevWeek() {
                    this.semaine.moment.subtract('7', 'd');
                    this.updateWeek();
                },
                nextWeek() {
                    this.semaine.moment.add('7', 'd');
                    this.updateWeek();
                },
                updateWeek() {
                    this.semaines = null;

                    this.semaine.numero = (this.semaine.moment.week() < 10 ? '0' : '') + this.semaine.moment.week();
                    this.semaine.annee = this.semaine.moment.format('YYYY');
                    this.semaine.debut = this.semaine.moment.clone().weekday(1).format('DD/MM/YY');
                    this.semaine.fin = this.semaine.moment.clone().weekday(7).format('DD/MM/YY');

                    this.updateRecap();
                    this.updateGrille();
                },
                selectWeek(week) {
                    this.semaines = null;
                    this.semaine = week;
                    this.updateRecap();
                    this.updateGrille();
                },
                showWeeks(annee) {
                    this.semaines = [];

                    for(let i = 1; i < 53; i++) {
                        const m = moment(annee, "YYYY").week(i);
                        let week = {
                            moment: m,
                            numero: (i < 10 ? '0' : '') + i,
                            annee: annee,
                            debut: m.clone().weekday(1).format('DD/MM/YY'),
                            fin: m.clone().weekday(7).format('DD/MM/YY'),
                            selected: false,
                            current: false,
                        };

                        if(parseInt(week.annee) === parseInt(this.semaine.annee) && parseInt(week.numero) === parseInt(this.semaine.numero)) {
                            week.selected = true;
                        }
                        if(parseInt(week.annee) === parseInt(moment().year()) && parseInt(week.numero) === parseInt(moment().week())) {
                            week.current = true;
                        }

                        this.semaines.push(week);
                    }

                    setTimeout(() => {
                        if(document.getElementsByClassName('week-selected').length) {
                            document.getElementById('week_list').scrollTo({ top: document.getElementsByClassName('week-selected')[0].offsetTop - 29, left: 0, behavior: 'instant'});
                        } else {
                            document.getElementById('week_list').scrollTo({ top: 0, left: 0, behavior: 'instant'});
                        }
                    }, 10);
                },
                updateRecap() {
                    if(!this.gardeSelected) {
                        this.loadingGrille = true;
                    }

                    Request.fetchJson(Router.generate('gardes.recap', {week: this.semaine.numero, year: this.semaine.annee}))
                        .then(data => {
                            this.recap = data.recap;
                            this.loadingGrille = false;

                            if (Array.isArray(this.recap)) {
                                this.recap = {};
                            }
                        })
                        .catch((e) => {
                            this.loadingGrille = false;
                        })
                    ;
                },
                updateGrille() {
                    if(this.gardeSelected) {
                        this.loadingGrille = true;

                        Request.fetchJson(Router.generate('gardes.grille', {codeSecteur: this.gardeSelected.id, week: this.semaine.numero, year: this.semaine.annee}))
                            .then(data => {
                                this.grille = data.grille;
                                this.grilleEquipe = data.grilleEquipe;
                                this.loadingGrille = false;

                                if (Array.isArray(this.grille)) {
                                    this.grille = {};
                                }
                            })
                            .catch((e) => {
                                this.loadingGrille = false;
                            })
                        ;
                    }
                },
                showMapForSecteur() {
                    this.showMap = true;

                    this.$refs.carto.cleanMap();
                    this.$refs.carto.centrer();
                    this.$refs.carto.zoomer();
                },
                globalClick() {
                    this.semaines = null;
                },
                showMapForCouverture(date, type) {
                    this.categorieSelected = type;

                    this.couvertureSelected = type;
                    this.couvertureDate = date ?? moment().format('YYYY-MM-DD');

                    this.showMap = true;
                    this.showCouverture = true;
                    this.loadingCouverture = true;
                    this.bufferCouverture = {};

                    this.$refs.carto.showAllCommunes();
                    this.$refs.carto.cleanMap();
                    this.$refs.carto.centrer();
                    this.$refs.carto.zoomer();

                    Request.fetchJson(Router.generate('garde.couverture'), 'POST', {
                        date: this.couvertureDate,
                        type: type,
                    }).then(response => {
                        if (response.success && response.buffer) {
                            this.bufferCouverture = response.buffer;

                            response.entities.forEach(entity => {
                                this.$refs.carto.drawMarker(entity);
                            });

                            this.gardesCouverture.forEach(g => {
                                if(this.bufferCouverture[g.code] && this.isCouvertureNoGarde(this.bufferCouverture[g.code])) {
                                    g.communeCollection.forEach(insee => {
                                        this.$refs.carto.setCommunesState(insee, 'warning');
                                    });
                                } else if (this.bufferCouverture[g.code] && this.bufferCouverture[g.code].length) {
                                    g.communeCollection.forEach(insee => {
                                        this.$refs.carto.setCommunesState(insee, 'covered');
                                    });

                                    this.bufferCouverture[g.code].forEach(r => {
                                        r.geocode = this.$refs.carto.markersDrawn[r.code];
                                    });
                                }
                            });

                            this.$refs.carto.applyCommunesState();
                        }

                        this.loadingCouverture = false;
                    }, () => {
                        this.loadingCouverture = false;
                    });
                },
                highlightGarde(garde) {
                    if(garde) {
                        garde.communeCollection.forEach(insee => {
                            this.$refs.carto.setCommunesState(insee, 'selected_noborder', true);
                        });
                    }
                    this.$refs.carto.applyCommunesState();
                },
                highlightRessource(code) {
                    this.$refs.carto.selectMarker(code);
                },
                affecterEquipe(plage, ressource, equipe) {
                    if(plage && ressource.numero) {
                        plage.saving = true;

                        Request.fetchJson(Router.generate('garde.affectation'), 'POST', {
                            plage: plage,
                            ressource: ressource,
                            equipe: equipe,
                        }).then(response => {
                            this.updateGrille();

                            plage.saving = false;
                        }, () => {
                            garde.saving = false;
                        });
                    }
                },
                addPlageTemp(jour, date, form) {
                    if(form.debut && form.fin
                    && form.debut.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)
                    && form.fin.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)
                    ) {
                        const debut = moment(form.debut, 'HH:mm'),
                            fin = moment(form.fin, 'HH:mm');

                        if(!this.grille['']) {
                            Vue.set(this.grille, '', {});
                        }
                        const token = '_' + form.debut + '_' + form.fin;

                        if(!this.grille[''][token]) {
                            Vue.set(this.grille[''], token, {});
                            Object.entries(this.semaineDates).forEach(([j, date]) => {
                                Vue.set(this.grille[''][token], j, {
                                    activated: j === jour,
                                    codeSecteur: this.gardeSelected.code,
                                    date: date.format('YYYY-MM-DD'),
                                    debut: form.debut,
                                    fin: form.fin,
                                    fonction: '',
                                    icone: 'fa-calendar-day',
                                    ressources: [],
                                    saving: false,
                                    shown: j === jour,
                                });
                            });
                        }

                        form.debut = '';
                        form.fin = '';
                        form.error = false;
                    } else {
                        form.error = 'Format horaire invalide.';
                    }
                }
            },
            computed: {
                gardeAmbulance() {
                    return this.gardeSelected && this.gardeSelected.type && this.gardeSelected.type.id === '002AM';
                },
                gardeMedecin() {
                    return this.gardeSelected && this.gardeSelected.type && this.gardeSelected.type.id === '002MG';
                },
                gardePharmacie() {
                    return this.gardeSelected && this.gardeSelected.type && this.gardeSelected.type.id === '002PH';
                },
                gardeSmur() {
                    return this.gardeSelected && this.gardeSelected.type && this.gardeSelected.type.id === '002SM';
                },
                gardeSamu() {
                    return this.gardeSelected && this.gardeSelected.type && this.gardeSelected.type.id === '002SA';
                },
                gardeAutre() {
                    return this.gardeSelected && !this.gardeAmbulance && !this.gardeMedecin && !this.gardePharmacie && !this.gardeSmur && !this.gardeSamu;
                },
                filteredAmbulances() {
                    return this.gardeAmbulance || this.gardeAutre ? this.ambulances.filter(ambulance => {
                        const matchSecteur = this.ambulanceShowAll || !this.gardeSelected.communeCollection.length || this.gardeSelected.communeCollection.indexOf(ambulance.insee) !== -1;
                        const matchSearch = !this.ambulanceSearch || ambulance.libelle.toUpperCase().indexOf(this.ambulanceSearch.toUpperCase()) !== -1;

                        return matchSecteur && matchSearch;
                    }) : [];
                },
                filteredMedecins() {
                    return this.gardeMedecin || this.gardeAutre ? this.medecins.filter(medecin => {
                        const matchSecteur = this.medecinShowAll || !this.gardeSelected.communeCollection.length || this.gardeSelected.communeCollection.indexOf(medecin.insee) !== -1;
                        const matchSearch = !this.medecinSearch || medecin.libelle.toUpperCase().indexOf(this.medecinSearch.toUpperCase()) !== -1;

                        return matchSecteur && matchSearch;
                    }) : [];
                },
                filteredPersonnels() {
                    return this.gardeSmur || this.gardeSamu || this.gardeAutre ? this.personnels.filter(personnel => {
                        const matchOrganisme = this.personnelShowAll || !this.gardeSelected.organisme.code || personnel.organismeCollection.indexOf(this.gardeSelected.organisme.code) !== -1;
                        const matchQualification = !this.personnelQualification || '' === this.personnelQualification || (personnel.qualificationCollection && personnel.qualificationCollection.indexOf(this.personnelQualification) !== -1);
                        const matchSearch = !this.personnelSearch || personnel.libelle.toUpperCase().indexOf(this.personnelSearch.toUpperCase()) !== -1;

                        return matchOrganisme && matchQualification && matchSearch;
                    }) : [];
                },
                filteredPharmacies() {
                    return this.gardePharmacie || this.gardeAutre ? this.pharmacies.filter(pharmacie => {
                        const matchSecteur = this.pharmacieShowAll || !this.gardeSelected.communeCollection.length || this.gardeSelected.communeCollection.indexOf(pharmacie.insee) !== -1;
                        const matchSearch = !this.pharmacieSearch || pharmacie.libelle.toUpperCase().indexOf(this.pharmacieSearch.toUpperCase()) !== -1;

                        return matchSecteur && matchSearch;
                    }) : [];
                },
                today() {
                    return moment().format('DD/MM/YY');
                },
                gardesCouverture() {
                    return this.gardes.filter(g => g.type && g.type.id === this.couvertureSelected);
                },
                equipes() {
                    return this.gardeSelected
                        && this.gardeSelected.type
                        && ['002SA', '002SM'].indexOf(this.gardeSelected.type.id) !== -1
                        && this.gardeSelected.organisme
                        && this.gardeSelected.organisme.equipeCollection
                    ? this.gardeSelected.organisme.equipeCollection : [];
                },
                paletteEquipe() {
                    const colors = [
                        '#db2763',
                        '#b0db43',
                        '#12eaea',
                        '#bce7fd',
                        '#c492b1',
                        '#d36135',
                        '#7fb069',
                        '#ece4b7',
                        '#e6aa68',
                        '#02020b',
                    ];
                    const paletteEquipe = {};

                    this.gardeSelected.organisme.equipeCollection.forEach((e, i) => {
                        if(colors[i]) {
                            paletteEquipe[e] = colors[i];
                        }
                    });

                    return paletteEquipe;
                },
                semaineDates () {
                    let semaineDates = {}

                    if(this.semaine && this.semaine.annee && this.semaine.numero) {
                        const m = moment(this.semaine.annee, "YYYY").week(parseInt(this.semaine.numero));

                        for(let i in this.semaineJours) {
                            semaineDates[this.semaineJours[i]] = m.clone().weekday(parseInt(i) + 1);
                        }
                    }

                    return semaineDates;
                },
                recapTotaux() {
                    let recapTotaux = {},
                        filteredGardes = this.gardes.filter(g => !this.categorieSelected || (g.type && this.categorieSelected === g.type.id)).map(g => g.code);

                    this.semaineJours.forEach(j => {
                        recapTotaux[j] = {ok: 0, ko: 0, na: 0};
                    });

                    if(this.recap) {
                        for (const [secteur, jours] of Object.entries(this.recap)) {
                            if(filteredGardes.indexOf(secteur) !== -1) {
                                for (const [jour, fonctions] of Object.entries(jours)) {
                                    for (const [fonction, plages] of Object.entries(fonctions)) {
                                        for (const [token, plage] of Object.entries(plages)) {
                                            if(plage.noGarde) {
                                                recapTotaux[jour].ko++;
                                            } else if (plage.okGarde) {
                                                recapTotaux[jour].ok++;
                                            } else {
                                                recapTotaux[jour].na++;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    return recapTotaux;
                }
            },
        });
    }
}