<template>
    <span v-if="timer !== null" :class="variant !== null ? 'text-'+variant : ''">
        {{ timer }} <slot></slot>
    </span>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: 'duree',
      validator: function (v) {
        return ['duree', 'rebours'].includes(v);
      },
    },
    seconds: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  data() {
    return {
      timer: null,
      variant: null,
    };
  },
  mounted() {
    setInterval(this.updateTimer, 1000);
    this.updateTimer();
  },
  computed: {
    date() {
      if (this.value) {
        return moment(this.value, 'DD/MM/YYYY HH:mm:ss');
      }

      return null;
    },
    inverse() {
      return 'rebours' !== this.type;
    }
  },
  methods: {
    updateTimer() {
      if (this.date) {
        const diff = this.date.diff(moment());
        const duration = moment.duration(diff).abs();
        this.timer = (!this.inverse && diff < 0 && Math.floor(duration.asMinutes()) > 0 ? '-':'')
            + ('' + Math.floor(duration.asHours())).padStart(2, '0')
            + ':'
            + ('' + Math.floor(duration.minutes())).padStart(2, '0')
            + (
                this.seconds
                ? (':' + ('' + Math.floor(duration.seconds())).padStart(2, '0'))
                : ''
            )
        ;
        if (this.type === 'disponible') {
          this.variant = duration.asMinutes() <= 15 ? 'info' : (duration.asMinutes() <= 30 ? 'warning' : 'danger');
        } else if (this.type === 'affecte') {
          this.variant = diff < 0 ? 'danger' : (duration.asMinutes() <= 15 ? 'warning' : (duration.asHours() <= 1 ? 'info' : null));
        }
      } else {
        this.timer = null;
        this.variant = null;
      }
    }
  }
}
</script>