import html2canvas from 'html2canvas';
import Visibility from 'visibilityjs';
import Request from './Request'

export default class {
    constructor(uri) {
        this.unload = false;
        this._uri = uri;

        this._publications = [];

        this._subscriptions = [
            {route: '/', callback: this._globalHandler}
        ];
        this._session = null;
        this._init = false;

        this._connectOptions = {
            maxRetries: 10000,
            initialRetryDelay: 1.5,
            maxRetryDelay: 10,
            retryDelayGrowth: 1.5,
            retryDelayJitter: 0.1,
        };
        this._client = WS.connect(this._uri, this._connectOptions);
        let listenersInitiated = false;

        this._client.on('socket/connect', (session) => {
            this._session = session;

            for(let data of this._subscriptions) {
                this._rawSubscribe(data.route, data.callback.bind(this));
            }
            this._rawPublish('/', {
                action: 'init',
                page: {
                    title: document.title,
                    url: window.location.href,
                    screenSize: window.screen.width + 'x' + window.screen.height,
                },
                visibility: Visibility.state(),
            });
            if (!listenersInitiated) {
                Visibility.change((e, state) => this._rawPublish('/', {action: 'visibility', state}));
                window.addEventListener('appelLocked', e => this._rawPublish('/', {action: 'appelLocked', state: e.detail.state}));
                window.addEventListener('popstate', e => {
                    if (e.state) {
                        if (e.state.documentTitle) {
                            document.title = e.state.documentTitle;
                        }
                        this._rawPublish('/', {
                            action: 'page',
                            page: {
                                title: e.state.documentTitle || window.document.title,
                                url: location.pathname,
                                screenSize: window.screen.width + 'x' + window.screen.height,
                            }
                        });
                    }
                });
                window.addEventListener('app.pushState', e => e.detail && this._rawPublish('/', {
                    action: 'page',
                    page: {
                        title: e.detail.state.documentTitle || window.document.title,
                        url: e.detail.url,
                        screenSize: window.screen.width + 'x' + window.screen.height,
                    }
                }));
                window.addEventListener('app.replaceState', e => e.detail && this._rawPublish('/', {
                    action: 'page',
                    page: {
                        title: e.detail.state.documentTitle || window.document.title,
                        url: e.detail.url,
                        screenSize: window.screen.width + 'x' + window.screen.height,
                    }
                }));
                listenersInitiated = true;
            }

            for(let data of this._publications) {
                this._rawPublish(data.route, data.data);
            }
            this._publications = [];
            this._init = true;

            document.dispatchEvent(new CustomEvent('websocket', {detail: {online: true}}));
        });

        let retries = 0;
        this._client.on('socket/disconnect', (error) => {
            this._session = null;
            this._init = true;

            if (retries !== 0 && retries % 3 === 0 && document.body.dataset.auth) {
                // Vérifie la session toutes les 15 secondes et redirige vers le login
                Request.fetchJson('/check-session');
            }

            if (!error.reason.includes('WS-1001')) {
                document.dispatchEvent(new CustomEvent('websocket', {detail: {online: false}}));

                // Si la connexion initiale échoue, il n'y a pas de nouvelle tentative
                if (
                    error.reason.includes('Connection could not be established')
                    || error.reason.includes('Connection was closed properly')
                ) {
                    // Fine, I'll do it myself
                    setTimeout(() => this._client._connect(this._uri, this._connectOptions), 5000);
                }
            }

            retries++;
        });
    }

    _globalHandler(uri, data) {
        let action = data.action;
        let param = data.param;

        if (action === 'refresh') {
            window.location.reload();
        } else if (action === 'message' && param && param.type && param.content) {
            document.dispatchEvent(new CustomEvent('app.flash', {detail: {type: param.type, flash: param.content}}));
        } else if (action === 'redirect') {
            if (data.param.startsWith('http')) {
                let win = window.open(data.param, '_blank');
                if (win) {
                    win.focus();
                } else {
                    window.location.href = data.param;
                }
            } else {
                window.location.href = data.param;
            }
        } else if (action === 'screenshot') {
            html2canvas(document.body).then((canvas) => {
                let data = canvas.toDataURL();

                this.publish('/', {
                    action: 'screenshot',
                    data: {
                        id: param.id,
                        image: data
                    }
                });
            });
        }
    }

    connect() {
        if(!this._session) {
            this._client._connect(this._uri);
        }
    }

    subscribe(route, callback) {
        if(this._session) {
            this._rawSubscribe(route, callback);
        }

        this._subscriptions.push({route, callback});
    }

    _rawSubscribe(route, callback) {
        this._session.subscribe(route, (uri, data) => {
            callback(uri, data);
        });
    }

    unsubscribe(route) {
        let isSubscribed = false;
        for (let i of this._subscriptions.keys()) {
            if (this._subscriptions[i].route === route) {
                isSubscribed = true;
                this._subscriptions.splice(i, 1);
            }
        }
        if (this._session && isSubscribed) {
            this._session.unsubscribe(route);
        }
    }

    publish(route, data) {
        if(this._session) {
            this._rawPublish(route, data);
        } else {
            this._publications.push({route, data});
        }
    }

    _rawPublish(route, data) {
        try {
            this._session.publish(route, data);
        } catch (e) {}
    }

    isInit() {
        return this._init;
    }

    isOnline() {
        return this._session !== null;
    }
};