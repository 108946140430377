<template>
    <b-button v-bind="$attrs" @click="openModal()">
        <slot></slot>
        <b-modal
            id="modal-recherche-sas"
            cancel-title="Annuler"
            centered
            header-bg-variant="info"
            header-text-variant="white"
            ok-title="Rechercher"
            ok-variant="outline-info"
            size="md"
            title="Recherche de médecins dans la plateforme SAS"
            v-model="modalShow"
            @ok="go()"
        >
            <b-form-group label="Rechercher" label-cols-xl="3">
                <b-form-radio-group
                    v-model="mode"
                    class="mt-2"
                    :options="[{text: 'Médecin traitant', value: 'medecinTraitant'}, {text: 'Spécialité', value: 'specialite'}]"
                    required
                ></b-form-radio-group>
            </b-form-group>
            <b-form-group v-if="'medecinTraitant' === mode" label="Nom" label-cols-xl="3">
                <form-typeahead
                    v-model="medecinTraitant"
                    class="form-control"
                    placeholder="Prénom nom..."
                    route="ajax.medecin.query"
                    @select="medecinTraitant = $event.identite"
                ></form-typeahead>
            </b-form-group>
            <b-form-group v-if="'specialite' === mode" label="Type" label-cols-xl="3">
                <form-select v-model="specialite" :options="SAS_SPECIALITES" :clearable="false" @input="specialiteAutre = ''" class="mb-2"></form-select>
                <b-form-input v-model="specialiteAutre" placeholder="Autre spécialité..." @input="specialite = SAS_SPECIALITES.find(v => v.code === 'AUTRE')"></b-form-input>
            </b-form-group>
        </b-modal>
    </b-button>
</template>

<script>
import {SAS_SPECIALITES} from "../../../utils/Constants";

export default {
    data() {
        return {
            modalShow: false,
            mode: 'specialite',
            medecinTraitant: null,
            specialite: null,
            specialiteAutre: null,
            SAS_SPECIALITES: SAS_SPECIALITES,
        };
    },
    props: {
        endpoint: {
            type: String,
            required: true,
        },
        medecin: {
            type: String,
            required: false,
            default: null,
        },
        origin: {
            type: String,
            required: true,
        },
        primaire: {
            required: true,
        },
    },
    methods: {
        openModal() {
            this.mode = this.medecin ? 'medecinTraitant' : 'specialite';
            this.medecinTraitant = this.medecin;
            this.specialite = this.SAS_SPECIALITES.find(v => v.code === 'urn:oid:1.2.250.1.213.2.28|SM54');
            this.specialiteAutre = '';
            this.modalShow = true;
        },
        go() {
            this.url && window.open(this.url, 'sas');
        },
    },
    computed: {
        url() {
            if (this.endpoint && this.origin) {
                return this.endpoint
                    + '/sas/recherche?'
                    + (new URLSearchParams({
                        origin: this.origin.trim(),
                        practitioner: 'medecinTraitant' === this.mode && this.medecinTraitant ? this.medecinTraitant.replace(/^\s*(dr\.|dr |doc\. |docteur )/i, '') : '',
                        specialty: 'specialite' === this.mode && this.specialite
                            ? ('AUTRE' === this.specialite.code && this.specialiteAutre ? this.specialiteAutre : ('AUTRE' === this.specialite.code ? '' : this.specialite.code))
                            : ''
                        ,
                        streetnumber: this.primaire && this.primaire.adresseNumero ? this.primaire.adresseNumero.replace(/ /g, '') : '',
                        streetname: this.primaire && this.primaire.adresse ? this.primaire.adresse.trim() : '',
                        inseecode: this.primaire && this.primaire.codeInsee ? this.primaire.codeInsee.trim() : '',
                        city: this.primaire && this.primaire.ville ? this.primaire.ville.replace(/ \(.+\)/, '').trim() : '',
                    })).toString();
            }

            return null;
        },
    },
}
</script>