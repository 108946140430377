<template>
    <div style="display: none;">
        <slot v-if="ready" />
    </div>
</template>

<script>
import { optionsMerger, propsBinder, findRealParent } from 'vue2-leaflet/src/utils/utils.js';
import PolylineMixin from 'vue2-leaflet/src/mixins/Polyline.js';
import Options from 'vue2-leaflet/src/mixins/Options.js';
import { DomEvent } from 'leaflet';
import { antPath } from 'leaflet-ant-path';

export default {
    mixins: [PolylineMixin, Options],
    props: {
        latLngs: {
            type: Array,
            default: () => [],
        },
        delay: {
            type: String,
            required: false,
            default: '2000',
        },
        color: {
            type: String,
            required: false,
            default: 'black',
        }
    },
    data() {
        return {
            ready: false,
        };
    },
    mounted() {
        this.mapObject = antPath(this.latLngs, {
            delay: this.delay,
            color: this.color,
            weight: 4,
            dashArray: [60, 20],
        });
        DomEvent.on(this.mapObject, this.$listeners);
        propsBinder(this, this.mapObject, this.$options.props);
        this.ready = true;
        this.parentContainer = findRealParent(this.$parent);
        this.parentContainer.addLayer(this, !this.visible);
        this.$nextTick(() => {
            this.$emit('ready', this.mapObject);
        });
    },
};
</script>