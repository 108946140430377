module.exports.SAS_SPECIALITES = [
    {
        code: 'urn:oid:1.2.250.1.213.2.28|SM57',
        libelle: 'Allergologie'
    },
    {
        code: 'urn:oid:1.2.250.1.213.2.28|SM03',
        libelle: 'Biologie médicale'
    },
    {
        code: 'urn:oid:1.2.250.1.213.2.28|SM04',
        libelle: 'Cardio et Maladies vasculaires'
    },
    {
        code: 'urn:oid:1.2.250.1.71.1.2.7|40',
        libelle: 'Chirurgien-Dentiste'
    },
    {
        code: 'urn:oid:1.2.250.1.213.2.28|SM15',
        libelle: 'Dermatologie, Vénéréologie'
    },
    {
        code: 'urn:oid:1.2.250.1.213.2.28|SM16',
        libelle: 'Endocrinologie, Métabolisme'
    },
    {
        code: 'urn:oid:1.2.250.1.213.2.28|SM19',
        libelle: 'Gynécologie médicale'
    },
    {
        code: 'urn:oid:1.2.250.1.213.2.28|SM20',
        libelle: 'Gynécologie-obstétrique'
    },
    {
        code: 'urn:oid:1.2.250.1.213.2.28|SM21',
        libelle: 'Hématologie'
    },
    {
        code: 'urn:oid:1.2.250.1.71.1.2.7|60',
        libelle: 'Infirmier'
    },
    {
        code: 'urn:oid:1.2.250.1.71.1.2.7|69',
        libelle: 'Infirmier psychiatrique'
    },
    {
        code: 'urn:oid:1.2.250.1.71.1.2.7|70',
        libelle: 'Masseur-Kinésithérapeute'
    },
    {
        code: 'urn:oid:1.2.250.1.213.2.28|SM54',
        libelle: 'Médecine générale'
    },
    {
        code: 'urn:oid:1.2.250.1.213.2.28|SM32',
        libelle: 'Neurologie'
    },
    {
        code: 'urn:oid:1.2.250.1.213.2.28|SM36',
        libelle: 'Oncologie, opt médicale'
    },
    {
        code: 'urn:oid:1.2.250.1.213.2.28|SM38',
        libelle: 'Ophtalmologie'
    },
    {
        code: 'urn:oid:1.2.250.1.213.2.28|SM39',
        libelle: 'ORL'
    },
    {
        code: 'urn:oid:1.2.250.1.71.1.2.7|83',
        libelle: 'Orthopédiste-Orthésiste'
    },
    {
        code: 'urn:oid:1.2.250.1.71.1.2.7|91',
        libelle: 'Orthophoniste'
    },
    {
        code: 'urn:oid:1.2.250.1.71.1.2.7|92',
        libelle: 'Orthoptiste'
    },
    {
        code: 'urn:oid:1.2.250.1.213.2.28|SM40',
        libelle: 'Pédiatrie'
    },
    {
        code: 'urn:oid:1.2.250.1.71.1.2.7|80',
        libelle: 'Pédicure-Podologue'
    },
    {
        code: 'urn:oid:1.2.250.1.213.2.28|SM42',
        libelle: 'Psychiatrie'
    },
    {
        code: 'urn:oid:1.2.250.1.213.2.28|SM43',
        libelle: 'Psychiatrie, opt enfant et ado'
    },
    {
        code: 'urn:oid:1.2.250.1.213.2.28|SM44',
        libelle: 'Radio-diagnostic'
    },
    {
        code: 'urn:oid:1.2.250.1.71.1.2.7|50',
        libelle: 'Sage-Femme'
    },
    {
        code: 'AUTRE',
        libelle: 'Autre'
    },
];