<template>
    <b-overlay :show="!isValid" :opacity="0.4">
        <template #overlay><span></span></template>
        <a
            v-bind="$attrs"
            class="btn btn-sm btn-outline-info"
            :class="{'brl-0': !borderRadiusLeft, 'brr-0': !borderRadiusRight}"
            :href="'tel:' + canonical"
            title="Appeler"
        >
            <i class="fa-fw fa-solid fa-phone"></i>
        </a>
    </b-overlay>
</template>

<script>
export default {
    props: {
        borderRadiusLeft: {
            type: Boolean,
            required: false,
            default: false,
        },
        borderRadiusRight: {
            type: Boolean,
            required: false,
            default: true,
        },
        telephone: {
            type: String,
            required: false,
            default: ''
        },
    },
    computed: {
        isValid() {
            return this.telephone && this.telephone.match(/^\s*\+?[\d.\s]+$/u);
        },
        canonical() {
            return this.telephone ? this.telephone.replace(/[^+\d]/ug, '') : '';
        },
    },
}
</script>