Object.defineProperty(Array.prototype, 'chunk', {
    value: function(chunkSize) {
        const R = [];
        for (let i = 0; i < this.length; i += chunkSize)
            R.push(this.slice(i, i + chunkSize));
        return R;
    }
});

Object.defineProperty(Number.prototype, 'toReadableSize', {
    value: function(digits = 2) {
        let octets = parseInt(this);
        const thresholds = ['To', 'Go', 'Mo', 'Ko', 'octets'];
        for (let index of thresholds.keys()) {
            let threshold = 1024 ** (thresholds.length - 1 - index);
            if (octets >= threshold) {
                return (octets / threshold).toLocaleString('fr-FR', {maximumFractionDigits: digits}) + '&nbsp;' + thresholds[index];
            }
        }

        return '0&nbsp;octet';
    }
});
