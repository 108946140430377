<template>
    <div v-if="root !== undefined && errors">
        <div class="p-3 my-3 alert alert-danger">
            <form-error-item v-for="(error, index) in errors" :key="index" :error="error"></form-error-item>
        </div>
    </div>
    <span class="invalid-feedback d-block" v-else-if="errors">
        <form-error-item v-for="(error, index) in errors" :key="index" :error="error"></form-error-item>
    </span>
</template>

<script>
    export default {
        props: ['errors', 'root'],
    }
</script>