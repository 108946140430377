import Vue from 'vue';

Vue.config.productionTip = false;

// Module général pour utiliser BootstrapVue sans data/props
export default class {
    constructor() {
        if (document.getElementById('bootstrap-vue-app')) {
            new Vue({
                el: '#bootstrap-vue-app',
                mounted() {
                    this.$el.classList.remove('invisible');
                }
            });
        }
    }
}
