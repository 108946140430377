<template>
    <datalist>
        <option v-for="option in options" :value="option"/>
    </datalist>

</template>

<script>
    export default {
        props: ['options'],
    }
</script>