export default {
    data: function() {
        return {
            flashMessageTypes: {
                welcome: {label: 'Info', style: 'secondary', autoHideDelay: 5000},
                success: {label: 'Succès', style: 'success', autoHideDelay: 5000},
                error: {label: 'Erreur', style: 'danger', autoHideDelay: 10000},
                warning: {label: 'Attention', style: 'warning', autoHideDelay: 10000},
                info: {label: 'Information', style: 'info', autoHideDelay: 5000},
            },
            newFlashMessageContent: null,
            newFlashMessageType: 'info',
        }
    },
    computed: {
        newFlashMessageTypeOptions() {
            return Object.entries(this.flashMessageTypes)
                .filter(a => a[0] !== 'welcome')
                .map(a => ({
                    value: a[0],
                    text: a[1].label,
                }))
            ;
        },
    },
}