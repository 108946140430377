<template>
    <input v-bind="$attrs" type="text" :readonly="readonly" :class="inputClass" v-model="currentValue" @input="handleInput"/>
</template>

<script>
    export default {
        inheritAttrs: false,
        props: ['value', 'readonly'],
        data() {
            return {
                currentValue: this.value,
                inputClass: null,
            };
        },
        mounted() {
            this.reset();
        },
        watch: {
            value: {
                deep: true,
                handler() {
                    this.reset();
                }
            }
        },
        methods: {
            reset() {
                this.currentValue = this.value;
                this.transformValue();
            },
            handleInput () {
                this.transformValue();
                this.$emit('input', this.currentValue);
            },
            transformValue() {
                let cleaned = (this.currentValue ?? '').toString();
                let matches = cleaned.match(/^(\+\d{3} ?|\d{2} ?)(\d{1,2} ?)?(\d{1,2} ?)?(\d{1,2} ?)?(\d{1,2} ?)?$/);

                if(matches && matches[1]) {
                    this.currentValue = matches[1];
                    for (let i = 2; i <= 5; i++) {
                        this.currentValue += matches[i] ? (this.currentValue.slice(-1) !== ' ' ? ' ' : '') + matches[i] : '';
                    }
                }
            }
        }
    }
</script>