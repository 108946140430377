<template>
    <b-modal
        ok
        centered
        header-bg-variant="info"
        header-text-variant="white"
        hide-footer
        :id="modalId"
        title="Envoyer un SMS"
        v-model="modalShow"
    >
        <b-form @submit.stop="onSubmit">
            <form-error :errors="errors['']" root></form-error>

            <b-form-group label="Téléphone" label-for="telephone">
                <b-form-input v-model="telephone" id="telephone" required type="tel"></b-form-input>
                <form-error :errors="errors['telephone']"></form-error>
            </b-form-group>
            <b-form-group label="Message" label-for="message">
                <b-form-textarea
                    v-model="message"
                    max-rows="10"
                    maxlength="918"
                    ref="message"
                    required
                    spellcheck="false"
                ></b-form-textarea>
                <div class="d-flex small justify-content-between">
                    <div>
                        <span
                            v-if="solde !== null"
                            :class="{
                                'text-danger font-weight-bold': SMS_ALERT != null && solde <= SMS_ALERT,
                                'text-warning': SMS_WARNING != null && solde <= SMS_WARNING && (SMS_ALERT === null || solde > SMS_ALERT),
                                'text-muted': (SMS_WARNING === null || solde > SMS_WARNING) && (SMS_ALERT === null || solde > SMS_ALERT),
                            }"
                            :title="decouvertAutorise !== null ? 'Découvert autorisé : ' + decouvertAutorise : null"
                        >Solde restant : {{ solde }}</span>
                    </div>
                    <div class="text-right" :class="message && message.length > 160 ? 'text-warning' : 'text-muted'">
                        <i v-if="message && message.length > 160" class="mr-1 fa-solid fa-circle-question" v-b-tooltip.hover title="Les messages de plus de 160 caractères entraînent un surcoût"></i>
                        {{ message ? message.length : 0 }} / 918 caractères
                    </div>
                </div>
                <form-error :errors="errors['message']"></form-error>
                <form-error :errors="invalidCharactersErrors"></form-error>
            </b-form-group>
            <div class="d-flex">
                <div class="flex-1">
                    <b-overlay :show="modelesLoading" spinner-small rounded opacity="0.6" class="d-inline-block">
                        <b-dropdown
                            :disabled="loading"
                            dropup
                            lazy
                            size="sm"
                            text="Modèle"
                            variant="secondary"
                            :boundary="'#' + modalId + ' .modal-dialog'"
                        >
                            <b-dropdown-item
                                v-for="modele in modeles"
                                :key="modele.code"
                                class="dropdown-item-sm"
                                v-b-tooltip.hover.right
                                :title="modele.texte"
                                @click="applyModele(modele)"
                            >{{ modele.libelle }}</b-dropdown-item>
                        </b-dropdown>
                    </b-overlay>
                </div>
                <b-button type="button" class="mr-2" @click="modalShow = false">Annuler</b-button>
                <b-overlay :show="loading" spinner-small rounded opacity="0.6" class="d-inline-block">
                    <b-button type="submit" variant="info" :disabled="invalidCharactersErrors.length > 0">Envoyer</b-button>
                </b-overlay>
            </div>
        </b-form>
    </b-modal>
</template>

<script>
import Request from "../../../utils/Request";
import Router from "../../../utils/Router";

export default {
    data() {
        return {
            decouvertAutorise: null,
            errors: {},
            invalidCharactersErrors: [],
            loading: false,
            message: null,
            modeles: [],
            modelesFetched: false,
            modelesLoading: false,
            modalShow: false,
            SMS_ALERT: null,
            SMS_WARNING: null,
            solde: null,
            telephone: null,
        };
    },
    props: {
        appel: {
            type: String,
            required: false,
            default: null,
        },
        csrf: {
            type: String,
            required: true,
            default: null,
        },
        messageInitial: {
            type: String,
            required: false,
            default: null,
        },
        modalId: {
            type: String,
            required: true,
        },
        telephoneInitial: {
            type: String,
            required: false,
            default: null,
        },
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();
            if (this.invalidCharactersErrors.length > 0) {
                return;
            }
            this.loading = true;
            this.errors = {};

            Request.fetchJson(
                Router.generate('ajax.sms.send'),
                'POST',
                {
                    sms: {
                        telephone: ('' + this.telephone).trim(),
                        message: this.message,
                        appel: this.appel,
                        _token: this.csrf,
                    }
                }
            ).then(data => {
                if (true === data.success) {
                    this.modalShow = false;
                    document.dispatchEvent(new CustomEvent('app.flash', {detail: {type: 'success', flash: 'SMS envoyé'}}));
                } else if (this.modalShow) {
                    this.errors = data.errors;
                } else {
                    document.dispatchEvent(new CustomEvent('app.flash', {detail: {type: 'error', flash: 'Le SMS n\'a pas pu être envoyé'}}));
                }
            }).finally(() => {
                this.loading = false;
            });
        },
        fetchModeles() {
            this.modelesLoading = true;
            Request.fetchJson(Router.generate('ajax.sms.modeles'))
                .then(modeles => {
                    this.modeles = modeles;
                    this.modelesFetched = true;
                })
                .finally(() => this.modelesLoading = false)
            ;
        },
        fetchSolde() {
            Request.fetchJson(Router.generate('ajax.sms.solde'))
                .then(data => {
                    this.solde = data.solde;
                    this.decouvertAutorise = data.decouvertAutorise;
                    this.SMS_ALERT = data.SMS_ALERT ? parseInt(data.SMS_ALERT) : null;
                    this.SMS_WARNING = data.SMS_WARNING ? parseInt(data.SMS_WARNING) : null;
                })
            ;
        },
        applyModele(modele) {
            this.message = modele.texte.trim().substr(0, 918).trim();
        },
    },
    watch: {
        modalShow(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.telephone = this.telephoneInitial;
                this.message = this.messageInitial;
                this.errors = {};
                this.loading = false;

                if (!this.modelesFetched && !this.modelesLoading) {
                    this.fetchModeles();
                }
                this.fetchSolde();
            }
        },
        message(newValue) {
            // Remplace les caractères courants et non autorisés qui augmentent le nombre de caractères
            if (newValue && newValue.includes('œ')) {
                this.message = this.message.replace(/œ/g, 'oe');
            }
            if (newValue && newValue.includes('Œ')) {
                this.message = this.message.replace(/Œ/g, 'Oe');
            }
            if (newValue && newValue.includes("\r")) {
                this.message = this.message.replace(/\r/g, '');
            }
            // Avertit des caractères non autorisés
            /** @see \App\Services\Manager\SmsManager::SMS_REGEX */
            let invalids = (newValue ?? '').match(/[^ 0-9a-zA-ZéèàùìòÇÅåÉæÆäöüâÂêÊîÎôÔûÛëË€Àç“,!?¿'()_%\/+=:.@;<>&*£$¥#\n-]/g);
            this.invalidCharactersErrors = invalids ? ['Les caractères suivants ne sont pas autorisés : ' + [...new Set(invalids)].join(' ')] : [];
        },
    }
}
</script>