import Vue from 'vue';
import FlashMessageMixin from "../vue/Mixins/FlashMessageMixin";

Vue.config.productionTip = false;

export default class {
    constructor() {
        new Vue({
            el: '#app',
            delimiters: ['[[', ']]'],
            data: {
                server: {},
                clients: [],
                me: null,
                newFlashMessageConnection: null,
                newFlashMessageUser: null,
            },
            mixins: [FlashMessageMixin],
            mounted() {
                webSocket.subscribe('administration/websocket', (uri, data) => {
                    if (data.action === 'init') {
                        this.server = data.server;
                    } else if (data.action === 'list') {
                        this.me = data.me;
                        this.clients = data.clients;
                    } else if (data.action === 'screenshot') {
                        $('#screenshotLoader').hide();
                        $('#screenshotImage').show().attr('src', data.image);
                    }
                });

                this.$el.classList.remove('invisible');
            },
            methods: {
                action(id, action, param = {}) {
                    webSocket.publish('administration/websocket', {action, id, param});

                    if (action === 'screenshot') {
                        $('#screenshotLoader').show();
                        $('#screenshotImage').hide();
                        $("#screenshotModalBtn").trigger('click');
                    } else if (action === 'message') {
                        this.$bvModal.hide('modal-supervision-message');
                        this.newFlashMessageContent = null;
                        this.newFlashMessageConnection = null;
                        this.newFlashMessageUser = null;
                    }
                }
            },
            computed: {
                filteredClients() {
                    let groupedClients = {};

                    this.clients.forEach(client => {
                        if (!(client.user.code in groupedClients)) {
                            groupedClients[client.user.code] = [];
                        }

                        groupedClients[client.user.code].push(client);
                    });

                    return Object.values(groupedClients);
                },
            },
        });
    }
}