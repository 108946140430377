import Vue from 'vue';
import Request from '../utils/Request';
import Router from '../utils/Router';

Vue.config.productionTip = false;

export default class {
    constructor() {
        new Vue({
            el: '#app',
            delimiters: ['[[', ']]'],
            data: {
                // Formulaire
                form: {
                    utilisateur: {
                        adresse1: null,
                        codePostal: null,
                        hasPassword: false,
                        jeton: null,
                        username: null,
                        ville: null,
                    },
                },
                passwordFieldType: 'password',
                // Vue
                codePersonnel: null,
                filters: {
                    dateDebut: null,
                    dateFin: null,
                    numeroAppel: null,
                    observation: null,
                    objet: null,
                },
                logs: {
                    count: 0,
                    countNoFilter: 0,
                    entities: [],
                    loading: false,
                    nbPages: 1,
                    page: 1,
                    perPage: 20,
                },
            },
            beforeMount() {
                const data = JSON.parse(this.$el.dataset.data);

                for (let key in data) {
                    this[key] = data[key];
                }
            },
            mounted() {
                this.$el.classList.remove('invisible');
            },
            methods: {
                generateJeton() {
                    this.form.utilisateur.jeton = new Array(3)
                        .fill('')
                        .map(() => Math.random().toString(36).slice(-8))
                        .join('')
                    ;
                },
                fetch(page, filters) {
                    this.logs.loading = true;
                    Request.fetchJson(
                        Router.generate('administration.personnel.view', {
                            code: this.codePersonnel,
                            page: page,
                            historique_filter: JSON.parse(JSON.stringify(filters)),
                        }))
                        .then(data => this.logs = data)
                        .finally(() => this.logs.loading = false)
                    ;
                },
            },
            computed: {
                jetonInvalide() {
                    return this.form.utilisateur.jeton && (
                        this.form.utilisateur.jeton.length < 12
                        || this.form.utilisateur.jeton.length > 36
                    )
                },
                currentPage() {
                    return this.logs.page;
                },
            },
            watch: {
                currentPage(newValue, oldValue) {
                    if (newValue !== oldValue) {
                        this.fetch(newValue, this.filters);
                    }
                },
                filters: {
                    deep: true,
                    handler: Request.debounce(function (newValue) {
                        this.fetch(this.currentPage, newValue);
                    }, 300),
                },
            },
        });
    }
}
